import { createReducer } from 'deox';

import {
  TGetOpenHoursResponse,
  TGetSaleHistoriesResponse,
  TGetSaleHistoryResponse,
  TGetTreatmentHistoryResponse,
  TGetTreatmentsHistoriesResponse,
} from '@/services/api/sale';
import {
  getOpenHoursAction,
  getSaleHistoriesAction,
  getSaleHistoryAction,
  getTreatmentHistoryAction,
  getTreatmentsHistoriesAction,
} from '@/redux/actions';
import { getOpenHoursUpdateState } from './get-open-hours';
import { getSaleHistoriesUpdateState } from './get-sale-histories';
import { getSaleHistoryUpdateState } from './get-sale-history';
import { getTreatmentHistoryUpdateState } from './get-treatment-history';
import { getTreatmentsHistoriesUpdateState } from './get-treatments-histories';

export type TSaleState = {
  getOpenHoursResponse?: TGetOpenHoursResponse;
  getSaleHistoriesResponse?: TGetSaleHistoriesResponse;
  getSaleHistoryResponse?: TGetSaleHistoryResponse;
  getTreatmentHistoryResponse?: TGetTreatmentHistoryResponse;
  getTreatmentsHistoriesResponse?: TGetTreatmentsHistoriesResponse;
};

const initialState: TSaleState = {
  getOpenHoursResponse: undefined,
  getSaleHistoriesResponse: undefined,
  getSaleHistoryResponse: undefined,
  getTreatmentHistoryResponse: undefined,
  getTreatmentsHistoriesResponse: undefined,
};

const SaleReducer = createReducer(initialState, (handleAction) => [
  handleAction(getOpenHoursAction.success, getOpenHoursUpdateState),
  handleAction(getSaleHistoriesAction.success, getSaleHistoriesUpdateState),
  handleAction(getSaleHistoryAction.success, getSaleHistoryUpdateState),
  handleAction(getTreatmentHistoryAction.success, getTreatmentHistoryUpdateState),
  handleAction(getTreatmentsHistoriesAction.success, getTreatmentsHistoriesUpdateState),
]);

export default SaleReducer;
