import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7075 14.7036C20.5923 13.9032 19.9743 13.2792 19.1787 13.0932C16.0863 12.3684 15.3111 10.5876 15.1803 8.61242C14.6319 8.51042 13.6563 8.40002 12.0003 8.40002C10.3443 8.40002 9.36868 8.51042 8.82028 8.61242C8.68948 10.5876 7.91428 12.3684 4.82188 13.0932C4.02628 13.2804 3.40828 13.9032 3.29308 14.7036L2.69668 18.834C2.48668 20.2884 3.55468 21.6 5.04028 21.6H18.9603C20.4447 21.6 21.5139 20.2884 21.3039 18.834L20.7075 14.7036ZM12.0003 18.5904C10.3263 18.5904 8.96908 17.2464 8.96908 15.5904C8.96908 13.9344 10.3263 12.5904 12.0003 12.5904C13.6743 12.5904 15.0315 13.9344 15.0315 15.5904C15.0315 17.2464 13.6731 18.5904 12.0003 18.5904ZM23.9403 7.20002C23.9115 5.40002 19.3299 2.40122 12.0003 2.40002C4.66948 2.40122 0.0878785 5.40002 0.0602785 7.20002C0.0326785 9.00002 0.0854786 11.3424 3.10228 10.9524C6.63148 10.4952 6.41428 9.26282 6.41428 7.50122C6.41428 6.27242 9.28468 5.97602 12.0003 5.97602C14.7159 5.97602 17.5851 6.27242 17.5863 7.50122C17.5863 9.26282 17.3691 10.4952 20.8983 10.9524C23.9139 11.3424 23.9679 9.00002 23.9403 7.20002Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
