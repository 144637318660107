import ApiService from '@/services/api';

// TYPES

export type TLoginKakaoParams = unknown;

export type TLoginKakaoMaterials = {
  params?: TLoginKakaoParams;
};

export type TLoginKakaoResponse = string;

// FUNCTION

export const loginKakao = async ({ params }: TLoginKakaoMaterials): Promise<TLoginKakaoResponse> => {
  const response = await ApiService.get(`/auth/login/kakao`, { params });
  return response.data;
};
