import React from 'react';
import classNames from 'classnames';

import { TIconProps } from './Icon.types';
import { EIconName } from './Icon.enums';
import './Icon.scss';

import Menu from './Menu';
import X from './X';
import ChervonDown from './ChervonDown';
import OldPhone from './OldPhone';
import MessageBubble from './MessageBubble';
import ChervonLeft from './ChervonLeft';
import ChervonRight from './ChervonRight';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';

const Icon: React.FC<TIconProps> = ({ name, className, color, onClick }) => {
  const renderIcon = (): React.ReactElement => {
    switch (name) {
      case EIconName.Menu:
        return <Menu color={color} />;
      case EIconName.X:
        return <X color={color} />;
      case EIconName.ChervonDown:
        return <ChervonDown color={color} />;
      case EIconName.OldPhone:
        return <OldPhone color={color} />;
      case EIconName.MessageBubble:
        return <MessageBubble color={color} />;
      case EIconName.ChervonLeft:
        return <ChervonLeft color={color} />;
      case EIconName.ChervonRight:
        return <ChervonRight color={color} />;
      case EIconName.ArrowLeft:
        return <ArrowLeft color={color} />;
      case EIconName.ArrowRight:
        return <ArrowRight color={color} />;
      default:
        return <></>;
    }
  };

  return (
    <div className={classNames('Icon', 'flex', 'justify-center', 'items-center', className)} onClick={onClick}>
      {renderIcon()}
    </div>
  );
};

export default Icon;
