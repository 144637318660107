import { TCustomer } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TLoginPhoneParams = unknown;
export type TLoginPhoneBody = {
  impUid: string;
};

export type TLoginPhoneMaterials = {
  params?: TLoginPhoneParams;
  body?: TLoginPhoneBody;
};

export type TLoginPhoneResponse = {
  jwtAccessToken: string;
  customer: TCustomer;
};

// FUNCTION

export const loginPhone = async ({ params, body }: TLoginPhoneMaterials): Promise<TLoginPhoneResponse> => {
  const response = await ApiService.post(`/auth/login/phone`, body, { params });
  return response.data;
};
