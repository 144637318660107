import { TTicket } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTicketPaths = {
  id: string | number;
};
export type TGetTicketParams = unknown;

export type TGetTicketMaterials = {
  paths?: TGetTicketPaths;
  params?: TGetTicketParams;
};

export type TGetTicketResponse = TTicket;

// FUNCTION

export const getTicket = async ({ paths, params }: TGetTicketMaterials): Promise<TGetTicketResponse> => {
  const response = await ApiService.get(`/tickets/${paths?.id}`, { params });
  return response.data;
};
