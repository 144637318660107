import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Modal from '@/components/Modal';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import Button from '@/components/Button';
import { EButtonStyleType } from '@/components/Button/Button.enums';
import ModalMapAddress from '@/containers/MapAddress/ModalMapAddress';
import { TRootState } from '@/redux/reducers';
import { TBranch } from '@/common/models';
import { formatPhoneNumber } from '@/utils/functions';

import { TModalBookingConsultationProps } from './ModalBookingConsultation.types.d';
import './ModalBookingConsultation.scss';

const ModalBookingConsultation: React.FC<TModalBookingConsultationProps> = ({ visible, onClose }) => {
  const isMobile = useSelector((state: TRootState) => state.uiReducer.device.isMobile);
  const branchesState = useSelector((state: TRootState) => state.branchReducer.getBranchesResponse);

  const [modalMapAddressState, setModalMapAddressState] = useState<{
    visible: boolean;
    branch?: TBranch;
  }>({
    visible: false,
  });

  const currentBranch = modalMapAddressState?.branch;

  const handleOpenModalMapAddress = (): void => {
    setModalMapAddressState({ ...modalMapAddressState, visible: true });
  };

  const handleCloseModalMapAddress = (): void => {
    setModalMapAddressState({ ...modalMapAddressState, visible: false });
  };

  const handleChangeBranch = (branch: TBranch): void => {
    setModalMapAddressState({ visible: false, branch });
  };

  useEffect(() => {
    if (branchesState) {
      setModalMapAddressState({
        visible: false,
        branch: branchesState?.[0],
      });
    }
  }, [branchesState]);

  return (
    <>
      <Modal width={590} title="상담예약하기" visible={visible} onClose={onClose}>
        <div className="ModalBookingConsultation-wrapper">
          <div className="ModalBookingConsultation-address-options">
            <Row gutter={32} align="middle">
              <Col span={12}>
                <div
                  className={classNames('ModalBookingConsultation-text', {
                    'headline-2': !isMobile,
                    'headline-1': isMobile,
                  })}
                >
                  에이블 메디스킨
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={classNames('ModalBookingConsultation-address-control flex items-center justify-center', {
                    'headline-1': !isMobile,
                    'title-3': isMobile,
                  })}
                  onClick={handleOpenModalMapAddress}
                >
                  {currentBranch?.branchName}
                  <Icon name={EIconName.ChervonDown} color={EIconColor.GRAY_05} />
                </div>
              </Col>
            </Row>
          </div>

          <div className="ModalBookingConsultation-contact">
            <div className="ModalBookingConsultation-contact-item">
              <Button
                link="https://pf.kakao.com/_GYdZxj"
                target="_blank"
                title="카카오톡 상담"
                styleType={EButtonStyleType.YELLOW}
                iconName={EIconName.MessageBubble}
                iconColor={EIconColor.JON}
                size="large"
              />
            </div>

            <div className="ModalBookingConsultation-contact-item">
              <a href={`tel: ${currentBranch?.phoneNumber}`}>
                <Button
                  title={formatPhoneNumber(currentBranch?.phoneNumber)}
                  styleType={EButtonStyleType.DEFAULT}
                  iconName={EIconName.OldPhone}
                  iconColor={EIconColor.GRAY_04}
                  size="large"
                />
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <ModalMapAddress
        {...modalMapAddressState}
        onChangeBranch={handleChangeBranch}
        onClose={handleCloseModalMapAddress}
      />
    </>
  );
};

export default ModalBookingConsultation;
