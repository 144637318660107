import { TTreatment } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTreatmentPaths = {
  id: string | number;
};
export type TGetTreatmentParams = unknown;

export type TGetTreatmentMaterials = {
  paths?: TGetTreatmentPaths;
  params?: TGetTreatmentParams;
};

export type TGetTreatmentResponse = TTreatment;

// FUNCTION

export const getTreatment = async ({ paths, params }: TGetTreatmentMaterials): Promise<TGetTreatmentResponse> => {
  const response = await ApiService.get(`/treatment/${paths?.id}`, { params });
  return response.data;
};
