import env from '@/env';
import AuthorizedInstance from '@/services/authorized-api';

const ApiService = AuthorizedInstance(env.api.baseUrl.service);

export default ApiService;
export * from './auth';
export * from './branch';
export * from './treatment';
export * from './customer';
export * from './schedule';
export * from './notice';
export * from './ticket';
export * from './inquiry';
export * from './sale';
export * from './point';
export * from './payment';
