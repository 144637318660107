import { TOrder, TSchedule } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TCreateScheduleParams = unknown;
export type TCreateScheduleBody = {
  treatment: string;
  branch: string;
  visitDate: string;
  customerRequests: string;
};

export type TCreateScheduleMaterials = {
  params?: TCreateScheduleParams;
  body?: TCreateScheduleBody;
  isNotLogged?: boolean;
};

export type TCreateScheduleResponse = {
  bill: TOrder;
  booking: TSchedule;
};

// FUNCTION

export const createSchedule = async ({
  params,
  body,
  isNotLogged,
}: TCreateScheduleMaterials): Promise<TCreateScheduleResponse> => {
  const response = await ApiService.post(`/schedules${isNotLogged ? '/booking' : ''}`, body, { params });
  return response.data;
};
