import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Link, useLocation } from '@reach/router';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import LogoKrLight from '@/assets/images/logo-kr-light.svg';
import { Paths } from '@/pages/routers';
import { TRootState } from '@/redux/reducers';
import ModalBookingConsultation from '@/containers/Footer/ModalBookingConsultation';
import TextResponsive from '@/components/TextResponsive';

import { TFooterProps } from './Footer.types.d';
import './Footer.scss';

const Footer: React.FC<TFooterProps> = () => {
  const { pathname } = useLocation();

  const isMobile = useSelector((state: TRootState) => state.uiReducer.device.isMobile);
  const [modalBookingConsultationState, setModalBookingConsultationState] = useState<{
    visible: boolean;
  }>({
    visible: false,
  });

  const handleOpenModalBookingConsultation = (): void => {
    setModalBookingConsultationState({ visible: true });
  };

  const handleCloseModalBookingConsultation = (): void => {
    setModalBookingConsultationState({ visible: false });
  };

  return (
    <div className="Footer">
      <div className="Footer-wrapper">
        <Row>
          <Col span={24} lg={{ span: 8 }}>
            <div className="Footer-image flex flex-col justify-center items-center">
              <Link to={Paths.Home} className="Footer-logo">
                <img src={LogoKrLight} alt="" />
              </Link>
              <TextResponsive
                className="Footer-logo-description text-center cursor-pointer"
                title="상담예약하기"
                responsive={{ desktop: 'headline-1', mobile: 'headline-3' }}
                onClick={handleOpenModalBookingConsultation}
              />
            </div>
          </Col>
          {isMobile ? (
            <Col span={24} lg={{ span: 16 }}>
              <div className="Footer-info-wrapper">
                <Row gutter={[16, 16]}>
                  <Col span={16}>
                    <div className="Footer-info">
                      <TextResponsive
                        className="Footer-info-title"
                        title="사업자 정보"
                        responsive={{ desktop: 'title-3', mobile: 'title-1' }}
                      />
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <TextResponsive
                            className="Footer-info-text"
                            title="상호명 주식회사 에이블코퍼레이션"
                            responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                          />
                        </Col>
                        <Col span={24}>
                          <TextResponsive
                            className="Footer-info-text"
                            title="대표자 ㅣ 신동식"
                            responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                          />
                        </Col>
                        <Col span={24}>
                          <a
                            href="tel: 290-86-02100"
                            className={classNames('Footer-info-text', { 'body-1': !isMobile, 'caption-2': isMobile })}
                          >
                            사업자등록번호 ㅣ 290-86-02100
                          </a>
                        </Col>
                        <Col span={24}>
                          <TextResponsive
                            className="Footer-info-text"
                            title="통신판매업 ㅣ 제2021-성남분당B-0262호"
                            responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                          />
                        </Col>
                        <Col span={24}>
                          <a
                            href="tel: 0507-1413-3533"
                            className={classNames('Footer-info-text', { 'body-1': !isMobile, 'caption-2': isMobile })}
                          >
                            0507-1413-3533
                          </a>
                        </Col>
                        <Col span={24}>
                          <TextResponsive
                            className="Footer-info-text"
                            title="(13566) 경기 성남시 분당구 성남대로 779번길 18"
                            responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="Footer-info">
                      <TextResponsive
                        className="Footer-info-title"
                        title="소비자 보호"
                        responsive={{ desktop: 'title-3', mobile: 'title-1' }}
                      />
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <Link
                            to={Paths.TermsOfUse}
                            className={classNames('Footer-info-text', {
                              'body-1': !isMobile,
                              'caption-2': isMobile,
                              active: pathname === Paths.TermsOfUse,
                            })}
                          >
                            이용약관
                          </Link>
                        </Col>
                        <Col span={24}>
                          <Link
                            to={Paths.PrivacyPolicy}
                            className={classNames('Footer-info-text', {
                              'body-1': !isMobile,
                              'caption-2': isMobile,
                              active: pathname === Paths.PrivacyPolicy,
                            })}
                          >
                            개인정보 처리방침
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="Footer-info">
                      <TextResponsive
                        className="Footer-info-title"
                        title="민원책임"
                        responsive={{ desktop: 'title-3', mobile: 'title-1' }}
                      />
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <TextResponsive
                            className="Footer-info-text"
                            title="에이블코퍼레이션에서 운영하는 사이트에서 판매되는 모든 상품 및 서비스는 에이블코퍼레이션에서 책임지고 있습니다."
                            responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                          />
                        </Col>
                        <Col span={12}>
                          <TextResponsive
                            className="Footer-info-text"
                            title="민원 담당자 : 신동식"
                            responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                          />
                        </Col>
                        <Col span={12}>
                          <a
                            href="tel: 031-701-3533"
                            className={classNames('Footer-info-text', { 'body-1': !isMobile, 'caption-2': isMobile })}
                          >
                            연락처 : 031-701-3533
                          </a>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          ) : (
            <Col span={24} lg={{ span: 16 }}>
              <div className="Footer-info-wrapper">
                <div className="Footer-info">
                  <TextResponsive
                    className="Footer-info-title"
                    title="사업자 정보"
                    responsive={{ desktop: 'title-3', mobile: 'title-1' }}
                  />
                  <Row gutter={[24, 16]}>
                    <Col>
                      <TextResponsive
                        className="Footer-info-text"
                        title="상호명 ㅣ 주식회사 에이블코퍼레이션"
                        responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                      />
                    </Col>
                    <Col>
                      <TextResponsive
                        className="Footer-info-text"
                        title="대표자명 ㅣ 신동식"
                        responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                      />
                    </Col>
                    <Col>
                      <a
                        href="tel: 290-86-02100"
                        className={classNames('Footer-info-text', { 'body-1': !isMobile, 'caption-2': isMobile })}
                      >
                        사업자등록번호 ㅣ 290-86-02100
                      </a>
                    </Col>
                    <Col>
                      <TextResponsive
                        className="Footer-info-text"
                        title="통신판매업 ㅣ 제2021-성남분당B-0262호"
                        responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                      />
                    </Col>
                    <Col>
                      <a
                        href="tel: 0507-1413-3533"
                        className={classNames('Footer-info-text', { 'body-1': !isMobile, 'caption-2': isMobile })}
                      >
                        0507-1413-3533
                      </a>
                    </Col>
                    <Col>
                      <TextResponsive
                        className="Footer-info-text"
                        title="(13566) 경기 성남시 분당구 성남대로779번길 18"
                        responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                      />
                    </Col>
                  </Row>
                  <TextResponsive
                    className="Footer-info-title"
                    title="소비자 보호"
                    responsive={{ desktop: 'title-3', mobile: 'title-1' }}
                  />
                  <Row gutter={[24, 16]}>
                    <Col>
                      <Link
                        to={Paths.TermsOfUse}
                        className={classNames('Footer-info-text', {
                          'body-1': !isMobile,
                          'caption-2': isMobile,
                          active: pathname === Paths.TermsOfUse,
                        })}
                      >
                        이용약관
                      </Link>
                    </Col>
                    <Col>
                      <Link
                        to={Paths.PrivacyPolicy}
                        className={classNames('Footer-info-text', {
                          'body-1': !isMobile,
                          'caption-2': isMobile,
                          active: pathname === Paths.PrivacyPolicy,
                        })}
                      >
                        개인정보 처리방침
                      </Link>
                    </Col>
                  </Row>
                </div>

                <div className="Footer-info">
                  <Row gutter={[24, 16]}>
                    <Col>
                      <TextResponsive
                        className="Footer-info-text"
                        title="에이블코퍼레이션에서 운영하는 사이트에서 판매되는 모든 상품 및 서비스는 에이블코퍼레이션에서 책임지고 있습니다."
                        responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                      />
                    </Col>
                    <Col>
                      <TextResponsive
                        className="Footer-info-text"
                        title="민원 담당자 : 신동식 ㅣ 연락처 : 031-701-3533"
                        responsive={{ desktop: 'body-1', mobile: 'caption-2' }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>

      <ModalBookingConsultation {...modalBookingConsultationState} onClose={handleCloseModalBookingConsultation} />
    </div>
  );
};

export default Footer;
