import { createReducer } from 'deox';

import {
  TCancelMyScheduleResponse,
  TChangeMyScheduleResponse,
  TCreateScheduleResponse,
  TGetMyScheduleResponse,
  TGetMySchedulesResponse,
  TGetScheduleBookingTodayResponse,
  TTotalBookingTodayResponse,
} from '@/services/api/schedule';
import {
  cancelMyScheduleAction,
  changeMyScheduleAction,
  createScheduleAction,
  getMyScheduleAction,
  getMySchedulesAction,
  getScheduleBookingTodayAction,
  totalBookingTodayAction,
} from '@/redux/actions';
import { cancelMyScheduleUpdateState } from './cancel-my-schedule';
import { changeMyScheduleUpdateState } from './change-my-schedule';
import { createScheduleUpdateState } from './create-schedule';
import { getMyScheduleUpdateState } from './get-my-schedule';
import { getMySchedulesUpdateState } from './get-my-schedules';
import { getScheduleBookingTodayUpdateState } from './get-schedule-booking-today';
import { totalBookingTodayUpdateState } from './total-booking-today';

export type TScheduleState = {
  cancelMyScheduleResponse?: TCancelMyScheduleResponse;
  changeMyScheduleResponse?: TChangeMyScheduleResponse;
  createScheduleResponse?: TCreateScheduleResponse;
  getMyScheduleResponse?: TGetMyScheduleResponse;
  getMySchedulesResponse?: TGetMySchedulesResponse;
  getScheduleBookingTodayResponse?: TGetScheduleBookingTodayResponse;
  totalBookingTodayResponse?: TTotalBookingTodayResponse;
};

const initialState: TScheduleState = {
  cancelMyScheduleResponse: undefined,
  changeMyScheduleResponse: undefined,
  createScheduleResponse: undefined,
  getMyScheduleResponse: undefined,
  getMySchedulesResponse: undefined,
  getScheduleBookingTodayResponse: undefined,
  totalBookingTodayResponse: undefined,
};

const ScheduleReducer = createReducer(initialState, (handleAction) => [
  handleAction(cancelMyScheduleAction.success, cancelMyScheduleUpdateState),
  handleAction(changeMyScheduleAction.success, changeMyScheduleUpdateState),
  handleAction(createScheduleAction.success, createScheduleUpdateState),
  handleAction(getMyScheduleAction.success, getMyScheduleUpdateState),
  handleAction(getMySchedulesAction.success, getMySchedulesUpdateState),
  handleAction(getScheduleBookingTodayAction.success, getScheduleBookingTodayUpdateState),
  handleAction(totalBookingTodayAction.success, totalBookingTodayUpdateState),
]);

export default ScheduleReducer;
