import { TOrder } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TPaymentTicketParams = unknown;
export type TPaymentTicketBody = unknown;

export type TPaymentTicketMaterials = {
  params?: TPaymentTicketParams;
  body?: TPaymentTicketBody;
};

export type TPaymentTicketResponse = TOrder;

// FUNCTION

export const paymentTicket = async ({ params, body }: TPaymentTicketMaterials): Promise<TPaymentTicketResponse> => {
  const response = await ApiService.post(`/tickets/payment`, body, { params });
  return response.data;
};
