import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createInquiryAction } from '@/redux/actions';
import { createInquiry, TCreateInquiryResponse } from '@/services/api';

// FUNCTION

export function* createInquirySaga(action: ActionType<typeof createInquiryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createInquiry, materials);
    const createInquiryResponse: TCreateInquiryResponse = response as TCreateInquiryResponse;
    yield put(createInquiryAction.success(createInquiryResponse));
    successCallback?.(createInquiryResponse);
  } catch (err) {
    yield put(createInquiryAction.failure(err));
    failedCallback?.(err);
  }
}
