import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTreatmentsHistoriesAction } from '@/redux/actions';
import { getTreatmentsHistories, TGetTreatmentsHistoriesResponse } from '@/services/api';

// FUNCTION

export function* getTreatmentsHistoriesSaga(
  action: ActionType<typeof getTreatmentsHistoriesAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTreatmentsHistories, materials);
    const getTreatmentsHistoriesResponse: TGetTreatmentsHistoriesResponse = response as TGetTreatmentsHistoriesResponse;
    yield put(getTreatmentsHistoriesAction.success(getTreatmentsHistoriesResponse));
    successCallback?.(getTreatmentsHistoriesResponse);
  } catch (err) {
    yield put(getTreatmentsHistoriesAction.failure(err));
    failedCallback?.(err);
  }
}
