import { combineReducers } from 'redux';

import { loadingReducer, errorReducer, successReducer } from './status';
import authReducer from './auth';
import branchReducer from './branch';
import customerReducer from './customer';
import inquiryReducer from './inquiry';
import noticeReducer from './notice';
import paymentReducer from './payment';
import pointReducer from './point';
import saleReducer from './sale';
import scheduleReducer from './schedule';
import ticketReducer from './ticket';
import treatmentReducer from './treatment';
import uiReducer from './ui';

const rootReducer = combineReducers({
  loadingReducer,
  errorReducer,
  successReducer,
  authReducer,
  branchReducer,
  customerReducer,
  inquiryReducer,
  noticeReducer,
  paymentReducer,
  pointReducer,
  saleReducer,
  scheduleReducer,
  ticketReducer,
  treatmentReducer,
  uiReducer,
});

export default rootReducer;

export type TRootState = ReturnType<typeof rootReducer>;
