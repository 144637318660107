import { createActionCreator } from 'deox';

import { TGetHistoryPointsMaterials, TGetHistoryPointsResponse } from '@/services/api/point/get-history-points';

// CONSTANTS

export enum EGetHistoryPointsAction {
  GET_HISTORY_POINTS = 'GET_HISTORY_POINTS',
  GET_HISTORY_POINTS_REQUEST = 'GET_HISTORY_POINTS_REQUEST',
  GET_HISTORY_POINTS_SUCCESS = 'GET_HISTORY_POINTS_SUCCESS',
  GET_HISTORY_POINTS_FAILED = 'GET_HISTORY_POINTS_FAILED',
}

// TYPES

export type TGetHistoryPointsRequest = {
  type: EGetHistoryPointsAction.GET_HISTORY_POINTS_REQUEST;
  payload: {
    materials: TGetHistoryPointsMaterials;
    successCallback?: (response: TGetHistoryPointsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetHistoryPointsSuccess = {
  type: EGetHistoryPointsAction.GET_HISTORY_POINTS_SUCCESS;
  payload: { response: TGetHistoryPointsResponse };
};

export type TGetHistoryPointsFailed = { type: EGetHistoryPointsAction.GET_HISTORY_POINTS_FAILED };

// FUNCTION

export const getHistoryPointsAction = {
  request: createActionCreator(
    EGetHistoryPointsAction.GET_HISTORY_POINTS_REQUEST,
    (resolve) =>
      (
        materials: TGetHistoryPointsMaterials,
        successCallback?: (response: TGetHistoryPointsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetHistoryPointsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetHistoryPointsAction.GET_HISTORY_POINTS_SUCCESS,
    (resolve) =>
      (response: TGetHistoryPointsResponse): TGetHistoryPointsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetHistoryPointsAction.GET_HISTORY_POINTS_FAILED,
    (resolve) =>
      (error: unknown): TGetHistoryPointsFailed =>
        resolve({ error }),
  ),
};
