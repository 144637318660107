import { createActionCreator } from 'deox';

import { TGetMySchedulesMaterials, TGetMySchedulesResponse } from '@/services/api/schedule/get-my-schedules';

// CONSTANTS

export enum EGetMySchedulesAction {
  GET_MY_SCHEDULES = 'GET_MY_SCHEDULES',
  GET_MY_SCHEDULES_REQUEST = 'GET_MY_SCHEDULES_REQUEST',
  GET_MY_SCHEDULES_SUCCESS = 'GET_MY_SCHEDULES_SUCCESS',
  GET_MY_SCHEDULES_FAILED = 'GET_MY_SCHEDULES_FAILED',
}

// TYPES

export type TGetMySchedulesRequest = {
  type: EGetMySchedulesAction.GET_MY_SCHEDULES_REQUEST;
  payload: {
    materials: TGetMySchedulesMaterials;
    successCallback?: (response: TGetMySchedulesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetMySchedulesSuccess = {
  type: EGetMySchedulesAction.GET_MY_SCHEDULES_SUCCESS;
  payload: { response: TGetMySchedulesResponse };
};

export type TGetMySchedulesFailed = { type: EGetMySchedulesAction.GET_MY_SCHEDULES_FAILED };

// FUNCTION

export const getMySchedulesAction = {
  request: createActionCreator(
    EGetMySchedulesAction.GET_MY_SCHEDULES_REQUEST,
    (resolve) =>
      (
        materials: TGetMySchedulesMaterials,
        successCallback?: (response: TGetMySchedulesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetMySchedulesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetMySchedulesAction.GET_MY_SCHEDULES_SUCCESS,
    (resolve) =>
      (response: TGetMySchedulesResponse): TGetMySchedulesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetMySchedulesAction.GET_MY_SCHEDULES_FAILED,
    (resolve) =>
      (error: unknown): TGetMySchedulesFailed =>
        resolve({ error }),
  ),
};
