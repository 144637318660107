import { TScheduleState } from '@/redux/reducers/schedule';
import { TTotalBookingTodaySuccess } from '@/redux/actions/schedule';

export const totalBookingTodayUpdateState = (
  state: TScheduleState,
  action: TTotalBookingTodaySuccess,
): TScheduleState => ({
  ...state,
  totalBookingTodayResponse: action.payload.response,
});
