import ApiService from '@/services/api';

// TYPES

export type TGetHistoryPointPaths = {
  id: string | number;
};
export type TGetHistoryPointParams = unknown;

export type TGetHistoryPointMaterials = {
  paths?: TGetHistoryPointPaths;
  params?: TGetHistoryPointParams;
};

export type TGetHistoryPointResponse = unknown;

// FUNCTION

export const getHistoryPoint = async ({
  paths,
  params,
}: TGetHistoryPointMaterials): Promise<TGetHistoryPointResponse> => {
  const response = await ApiService.get(`/points/${paths?.id}`, { params });
  return response.data;
};
