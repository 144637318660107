import { createReducer } from 'deox';

import { TGetProfileResponse } from '@/services/api/customer';
import { getProfileAction } from '@/redux/actions';
import { getProfileUpdateState } from './get-profile';

export type TCustomerState = {
  getProfileResponse?: TGetProfileResponse;
};

const initialState: TCustomerState = {
  getProfileResponse: undefined,
};

const CustomerReducer = createReducer(initialState, (handleAction) => [
  handleAction(getProfileAction.success, getProfileUpdateState),
]);

export default CustomerReducer;
