import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getScheduleBookingTodayAction } from '@/redux/actions';
import { getScheduleBookingToday, TGetScheduleBookingTodayResponse } from '@/services/api';

// FUNCTION

export function* getScheduleBookingTodaySaga(
  action: ActionType<typeof getScheduleBookingTodayAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getScheduleBookingToday, materials);
    const getScheduleBookingTodayResponse: TGetScheduleBookingTodayResponse =
      response as TGetScheduleBookingTodayResponse;
    yield put(getScheduleBookingTodayAction.success(getScheduleBookingTodayResponse));
    successCallback?.(getScheduleBookingTodayResponse);
  } catch (err) {
    yield put(getScheduleBookingTodayAction.failure(err));
    failedCallback?.(err);
  }
}
