import { createActionCreator } from 'deox';

import { TGetPaymentHistoryMaterials, TGetPaymentHistoryResponse } from '@/services/api/payment/get-payment-history';

// CONSTANTS

export enum EGetPaymentHistoryAction {
  GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY',
  GET_PAYMENT_HISTORY_REQUEST = 'GET_PAYMENT_HISTORY_REQUEST',
  GET_PAYMENT_HISTORY_SUCCESS = 'GET_PAYMENT_HISTORY_SUCCESS',
  GET_PAYMENT_HISTORY_FAILED = 'GET_PAYMENT_HISTORY_FAILED',
}

// TYPES

export type TGetPaymentHistoryRequest = {
  type: EGetPaymentHistoryAction.GET_PAYMENT_HISTORY_REQUEST;
  payload: {
    materials: TGetPaymentHistoryMaterials;
    successCallback?: (response: TGetPaymentHistoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPaymentHistorySuccess = {
  type: EGetPaymentHistoryAction.GET_PAYMENT_HISTORY_SUCCESS;
  payload: { response: TGetPaymentHistoryResponse };
};

export type TGetPaymentHistoryFailed = { type: EGetPaymentHistoryAction.GET_PAYMENT_HISTORY_FAILED };

// FUNCTION

export const getPaymentHistoryAction = {
  request: createActionCreator(
    EGetPaymentHistoryAction.GET_PAYMENT_HISTORY_REQUEST,
    (resolve) =>
      (
        materials: TGetPaymentHistoryMaterials,
        successCallback?: (response: TGetPaymentHistoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPaymentHistoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPaymentHistoryAction.GET_PAYMENT_HISTORY_SUCCESS,
    (resolve) =>
      (response: TGetPaymentHistoryResponse): TGetPaymentHistorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPaymentHistoryAction.GET_PAYMENT_HISTORY_FAILED,
    (resolve) =>
      (error: unknown): TGetPaymentHistoryFailed =>
        resolve({ error }),
  ),
};
