import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getSaleHistoriesAction } from '@/redux/actions';
import { getSaleHistories, TGetSaleHistoriesResponse } from '@/services/api';

// FUNCTION

export function* getSaleHistoriesSaga(action: ActionType<typeof getSaleHistoriesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getSaleHistories, materials);
    const getSaleHistoriesResponse: TGetSaleHistoriesResponse = response as TGetSaleHistoriesResponse;
    yield put(getSaleHistoriesAction.success(getSaleHistoriesResponse));
    successCallback?.(getSaleHistoriesResponse);
  } catch (err) {
    yield put(getSaleHistoriesAction.failure(err));
    failedCallback?.(err);
  }
}
