import { createActionCreator } from 'deox';

import { TGetTicketMaterials, TGetTicketResponse } from '@/services/api/ticket/get-ticket';

// CONSTANTS

export enum EGetTicketAction {
  GET_TICKET = 'GET_TICKET',
  GET_TICKET_REQUEST = 'GET_TICKET_REQUEST',
  GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS',
  GET_TICKET_FAILED = 'GET_TICKET_FAILED',
}

// TYPES

export type TGetTicketRequest = {
  type: EGetTicketAction.GET_TICKET_REQUEST;
  payload: {
    materials: TGetTicketMaterials;
    successCallback?: (response: TGetTicketResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTicketSuccess = {
  type: EGetTicketAction.GET_TICKET_SUCCESS;
  payload: { response: TGetTicketResponse };
};

export type TGetTicketFailed = { type: EGetTicketAction.GET_TICKET_FAILED };

// FUNCTION

export const getTicketAction = {
  request: createActionCreator(
    EGetTicketAction.GET_TICKET_REQUEST,
    (resolve) =>
      (
        materials: TGetTicketMaterials,
        successCallback?: (response: TGetTicketResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTicketRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTicketAction.GET_TICKET_SUCCESS,
    (resolve) =>
      (response: TGetTicketResponse): TGetTicketSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTicketAction.GET_TICKET_FAILED,
    (resolve) =>
      (error: unknown): TGetTicketFailed =>
        resolve({ error }),
  ),
};
