import { TTicketState } from '@/redux/reducers/ticket';
import { TGetTicketsHistoriesSuccess } from '@/redux/actions/ticket';

export const getTicketsHistoriesUpdateState = (
  state: TTicketState,
  action: TGetTicketsHistoriesSuccess,
): TTicketState => ({
  ...state,
  getTicketsHistoriesResponse: action.payload.response,
});
