import { all, takeLatest } from 'redux-saga/effects';

import { getNoticeAction, getNoticesAction } from '@/redux/actions';

import { getNoticeSaga } from './get-notice';
import { getNoticesSaga } from './get-notices';

export default function* root(): Generator {
  yield all([
    takeLatest(getNoticeAction.request.type, getNoticeSaga),
    takeLatest(getNoticesAction.request.type, getNoticesSaga),
  ]);
}
