import { createActionCreator } from 'deox';

import { TGetSaleHistoryMaterials, TGetSaleHistoryResponse } from '@/services/api/sale/get-sale-history';

// CONSTANTS

export enum EGetSaleHistoryAction {
  GET_SALE_HISTORY = 'GET_SALE_HISTORY',
  GET_SALE_HISTORY_REQUEST = 'GET_SALE_HISTORY_REQUEST',
  GET_SALE_HISTORY_SUCCESS = 'GET_SALE_HISTORY_SUCCESS',
  GET_SALE_HISTORY_FAILED = 'GET_SALE_HISTORY_FAILED',
}

// TYPES

export type TGetSaleHistoryRequest = {
  type: EGetSaleHistoryAction.GET_SALE_HISTORY_REQUEST;
  payload: {
    materials: TGetSaleHistoryMaterials;
    successCallback?: (response: TGetSaleHistoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetSaleHistorySuccess = {
  type: EGetSaleHistoryAction.GET_SALE_HISTORY_SUCCESS;
  payload: { response: TGetSaleHistoryResponse };
};

export type TGetSaleHistoryFailed = { type: EGetSaleHistoryAction.GET_SALE_HISTORY_FAILED };

// FUNCTION

export const getSaleHistoryAction = {
  request: createActionCreator(
    EGetSaleHistoryAction.GET_SALE_HISTORY_REQUEST,
    (resolve) =>
      (
        materials: TGetSaleHistoryMaterials,
        successCallback?: (response: TGetSaleHistoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetSaleHistoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetSaleHistoryAction.GET_SALE_HISTORY_SUCCESS,
    (resolve) =>
      (response: TGetSaleHistoryResponse): TGetSaleHistorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetSaleHistoryAction.GET_SALE_HISTORY_FAILED,
    (resolve) =>
      (error: unknown): TGetSaleHistoryFailed =>
        resolve({ error }),
  ),
};
