import { createActionCreator } from 'deox';

import { TCancelMyScheduleMaterials, TCancelMyScheduleResponse } from '@/services/api/schedule/cancel-my-schedule';

// CONSTANTS

export enum ECancelMyScheduleAction {
  CANCEL_MY_SCHEDULE = 'CANCEL_MY_SCHEDULE',
  CANCEL_MY_SCHEDULE_REQUEST = 'CANCEL_MY_SCHEDULE_REQUEST',
  CANCEL_MY_SCHEDULE_SUCCESS = 'CANCEL_MY_SCHEDULE_SUCCESS',
  CANCEL_MY_SCHEDULE_FAILED = 'CANCEL_MY_SCHEDULE_FAILED',
}

// TYPES

export type TCancelMyScheduleRequest = {
  type: ECancelMyScheduleAction.CANCEL_MY_SCHEDULE_REQUEST;
  payload: {
    materials: TCancelMyScheduleMaterials;
    successCallback?: (response: TCancelMyScheduleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCancelMyScheduleSuccess = {
  type: ECancelMyScheduleAction.CANCEL_MY_SCHEDULE_SUCCESS;
  payload: { response: TCancelMyScheduleResponse };
};

export type TCancelMyScheduleFailed = { type: ECancelMyScheduleAction.CANCEL_MY_SCHEDULE_FAILED };

// FUNCTION

export const cancelMyScheduleAction = {
  request: createActionCreator(
    ECancelMyScheduleAction.CANCEL_MY_SCHEDULE_REQUEST,
    (resolve) =>
      (
        materials: TCancelMyScheduleMaterials,
        successCallback?: (response: TCancelMyScheduleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCancelMyScheduleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECancelMyScheduleAction.CANCEL_MY_SCHEDULE_SUCCESS,
    (resolve) =>
      (response: TCancelMyScheduleResponse): TCancelMyScheduleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECancelMyScheduleAction.CANCEL_MY_SCHEDULE_FAILED,
    (resolve) =>
      (error: unknown): TCancelMyScheduleFailed =>
        resolve({ error }),
  ),
};
