import { createReducer } from 'deox';

import {
  TAuthLoginResponse,
  TGenerateKakaoCodeResponse,
  TLoginKakaoResponse,
  TLoginPhoneResponse,
  TLogoutKakaoResponse,
} from '@/services/api/auth';
import {
  authLoginAction,
  generateKakaoCodeAction,
  loginKakaoAction,
  loginPhoneAction,
  logoutKakaoAction,
} from '@/redux/actions';
import { authLoginUpdateState } from './auth-login';
import { generateKakaoCodeUpdateState } from './generate-kakao-code';
import { loginKakaoUpdateState } from './login-kakao';
import { loginPhoneUpdateState } from './login-phone';
import { logoutKakaoUpdateState } from './logout-kakao';

export type TAuthState = {
  authLoginResponse?: TAuthLoginResponse;
  generateKakaoCodeResponse?: TGenerateKakaoCodeResponse;
  loginKakaoResponse?: TLoginKakaoResponse;
  loginPhoneResponse?: TLoginPhoneResponse;
  logoutKakaoResponse?: TLogoutKakaoResponse;
};

const initialState: TAuthState = {
  authLoginResponse: undefined,
  generateKakaoCodeResponse: undefined,
  loginKakaoResponse: undefined,
  loginPhoneResponse: undefined,
  logoutKakaoResponse: undefined,
};

const AuthReducer = createReducer(initialState, (handleAction) => [
  handleAction(authLoginAction.success, authLoginUpdateState),
  handleAction(generateKakaoCodeAction.success, generateKakaoCodeUpdateState),
  handleAction(loginKakaoAction.success, loginKakaoUpdateState),
  handleAction(loginPhoneAction.success, loginPhoneUpdateState),
  handleAction(logoutKakaoAction.success, logoutKakaoUpdateState),
]);

export default AuthReducer;
