import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import Modal from '@/components/Modal';
import { EButtonStyleType } from '@/components/Button/Button.enums';
import { TRootState } from '@/redux/reducers';
import { TBranch } from '@/common/models';
import Button from '@/components/Button';

import { TModalMapAddressProps } from './ModalMapAddress.types.d';

const ModalMapAddress: React.FC<TModalMapAddressProps> = ({ visible, branch, onChangeBranch, onClose }) => {
  const isMobile = useSelector((state: TRootState) => state.uiReducer.device.isMobile);
  const branchesState = useSelector((state: TRootState) => state.branchReducer.getBranchesResponse);

  const handleChangeBranch = (data: TBranch): void => {
    onChangeBranch?.(data);
  };

  const isActive = (data: TBranch): EButtonStyleType => {
    return data?.id === branch?.id ? EButtonStyleType.BLACK : EButtonStyleType.GRAY;
  };

  return (
    <Modal width={450} visible={visible} onClose={onClose} title="지점 선택" className="ModalMapAddress" hideFooter>
      <Row gutter={[20, 16]}>
        {branchesState?.map((item) => (
          <Col key={item.id} span={12}>
            <Button
              styleType={isActive(item)}
              title={item.branchName}
              onClick={(): void => handleChangeBranch(item)}
              size={isMobile ? 'middle' : 'small'}
            />
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default ModalMapAddress;
