import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTicketsHistoriesAction } from '@/redux/actions';
import { getTicketsHistories, TGetTicketsHistoriesResponse } from '@/services/api';

// FUNCTION

export function* getTicketsHistoriesSaga(action: ActionType<typeof getTicketsHistoriesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTicketsHistories, materials);
    const getTicketsHistoriesResponse: TGetTicketsHistoriesResponse = response as TGetTicketsHistoriesResponse;
    yield put(getTicketsHistoriesAction.success(getTicketsHistoriesResponse));
    successCallback?.(getTicketsHistoriesResponse);
  } catch (err) {
    yield put(getTicketsHistoriesAction.failure(err));
    failedCallback?.(err);
  }
}
