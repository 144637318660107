import { TPaymentState } from '@/redux/reducers/payment';
import { TGetPaymentHistorySuccess } from '@/redux/actions/payment';

export const getPaymentHistoryUpdateState = (
  state: TPaymentState,
  action: TGetPaymentHistorySuccess,
): TPaymentState => ({
  ...state,
  getPaymentHistoryResponse: action.payload.response,
});
