import { createActionCreator } from 'deox';

import { TGetSaleHistoriesMaterials, TGetSaleHistoriesResponse } from '@/services/api/sale/get-sale-histories';

// CONSTANTS

export enum EGetSaleHistoriesAction {
  GET_SALE_HISTORIES = 'GET_SALE_HISTORIES',
  GET_SALE_HISTORIES_REQUEST = 'GET_SALE_HISTORIES_REQUEST',
  GET_SALE_HISTORIES_SUCCESS = 'GET_SALE_HISTORIES_SUCCESS',
  GET_SALE_HISTORIES_FAILED = 'GET_SALE_HISTORIES_FAILED',
}

// TYPES

export type TGetSaleHistoriesRequest = {
  type: EGetSaleHistoriesAction.GET_SALE_HISTORIES_REQUEST;
  payload: {
    materials: TGetSaleHistoriesMaterials;
    successCallback?: (response: TGetSaleHistoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetSaleHistoriesSuccess = {
  type: EGetSaleHistoriesAction.GET_SALE_HISTORIES_SUCCESS;
  payload: { response: TGetSaleHistoriesResponse };
};

export type TGetSaleHistoriesFailed = { type: EGetSaleHistoriesAction.GET_SALE_HISTORIES_FAILED };

// FUNCTION

export const getSaleHistoriesAction = {
  request: createActionCreator(
    EGetSaleHistoriesAction.GET_SALE_HISTORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetSaleHistoriesMaterials,
        successCallback?: (response: TGetSaleHistoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetSaleHistoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetSaleHistoriesAction.GET_SALE_HISTORIES_SUCCESS,
    (resolve) =>
      (response: TGetSaleHistoriesResponse): TGetSaleHistoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetSaleHistoriesAction.GET_SALE_HISTORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetSaleHistoriesFailed =>
        resolve({ error }),
  ),
};
