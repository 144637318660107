import { createReducer } from 'deox';

import {
  TGetPaymentHistoriesResponse,
  TGetPaymentHistoryResponse,
  TPaymentCompleteResponse,
} from '@/services/api/payment';
import { getPaymentHistoriesAction, getPaymentHistoryAction, paymentCompleteAction } from '@/redux/actions';
import { getPaymentHistoriesUpdateState } from './get-payment-histories';
import { getPaymentHistoryUpdateState } from './get-payment-history';
import { paymentCompleteUpdateState } from './payment-complete';

export type TPaymentState = {
  getPaymentHistoriesResponse?: TGetPaymentHistoriesResponse;
  getPaymentHistoryResponse?: TGetPaymentHistoryResponse;
  paymentCompleteResponse?: TPaymentCompleteResponse;
};

const initialState: TPaymentState = {
  getPaymentHistoriesResponse: undefined,
  getPaymentHistoryResponse: undefined,
  paymentCompleteResponse: undefined,
};

const PaymentReducer = createReducer(initialState, (handleAction) => [
  handleAction(getPaymentHistoriesAction.success, getPaymentHistoriesUpdateState),
  handleAction(getPaymentHistoryAction.success, getPaymentHistoryUpdateState),
  handleAction(paymentCompleteAction.success, paymentCompleteUpdateState),
]);

export default PaymentReducer;
