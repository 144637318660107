const env = {
  siteName: process.env.REACT_APP_SITE_NAME ?? '',
  domain: process.env.REACT_APP_DOMAIN_NAME ?? '',
  rootUrl: process.env.REACT_APP_ROOT_URL ?? '',
  api: {
    baseUrl: {
      service: process.env.REACT_APP_SERVICE_BASE_URL ?? '',
    },
    iamport: process.env.REACT_APP_PAYMENT_ID_SELLER ?? '',
    storeId: process.env.REACT_APP_STORE_ID ?? '',
    merchantId: process.env.REACT_APP_MERCHANT_ID ?? '',
  },
  cookie: {
    domain: process.env.REACT_APP_COOKIE_DOMAIN ?? '',
  },
};

export default env;
