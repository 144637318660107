import { createReducer } from 'deox';

import {
  TGetTicketResponse,
  TGetTicketsHistoriesResponse,
  TGetTicketsResponse,
  TPaymentTicketResponse,
} from '@/services/api/ticket';
import { getTicketAction, getTicketsHistoriesAction, getTicketsAction, paymentTicketAction } from '@/redux/actions';
import { getTicketUpdateState } from './get-ticket';
import { getTicketsHistoriesUpdateState } from './get-tickets-histories';
import { getTicketsUpdateState } from './get-tickets';
import { paymentTicketUpdateState } from './payment-ticket';

export type TTicketState = {
  getTicketResponse?: TGetTicketResponse;
  getTicketsHistoriesResponse?: TGetTicketsHistoriesResponse;
  getTicketsResponse?: TGetTicketsResponse;
  paymentTicketResponse?: TPaymentTicketResponse;
};

const initialState: TTicketState = {
  getTicketResponse: undefined,
  getTicketsHistoriesResponse: undefined,
  getTicketsResponse: undefined,
  paymentTicketResponse: undefined,
};

const TicketReducer = createReducer(initialState, (handleAction) => [
  handleAction(getTicketAction.success, getTicketUpdateState),
  handleAction(getTicketsHistoriesAction.success, getTicketsHistoriesUpdateState),
  handleAction(getTicketsAction.success, getTicketsUpdateState),
  handleAction(paymentTicketAction.success, paymentTicketUpdateState),
]);

export default TicketReducer;
