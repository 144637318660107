import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTreatmentHistoryAction } from '@/redux/actions';
import { getTreatmentHistory, TGetTreatmentHistoryResponse } from '@/services/api';

// FUNCTION

export function* getTreatmentHistorySaga(action: ActionType<typeof getTreatmentHistoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTreatmentHistory, materials);
    const getTreatmentHistoryResponse: TGetTreatmentHistoryResponse = response as TGetTreatmentHistoryResponse;
    yield put(getTreatmentHistoryAction.success(getTreatmentHistoryResponse));
    successCallback?.(getTreatmentHistoryResponse);
  } catch (err) {
    yield put(getTreatmentHistoryAction.failure(err));
    failedCallback?.(err);
  }
}
