import ApiService from '@/services/api';

// TYPES

export type TChangeMySchedulePaths = {
  id: string | number;
};
export type TChangeMyScheduleBody = unknown;

export type TChangeMyScheduleMaterials = {
  paths?: TChangeMySchedulePaths;
  body?: TChangeMyScheduleBody;
};

export type TChangeMyScheduleResponse = unknown;

// FUNCTION

export const changeMySchedule = async ({
  paths,
  body,
}: TChangeMyScheduleMaterials): Promise<TChangeMyScheduleResponse> => {
  const response = await ApiService.patch(`/schedules/${paths?.id}`, body);
  return response.data;
};
