import { all, takeLatest } from 'redux-saga/effects';

import { getHistoryPointAction, getHistoryPointsAction } from '@/redux/actions';

import { getHistoryPointSaga } from './get-history-point';
import { getHistoryPointsSaga } from './get-history-points';

export default function* root(): Generator {
  yield all([
    takeLatest(getHistoryPointAction.request.type, getHistoryPointSaga),
    takeLatest(getHistoryPointsAction.request.type, getHistoryPointsSaga),
  ]);
}
