import { createActionCreator } from 'deox';

import { TGetTreatmentHistoryMaterials, TGetTreatmentHistoryResponse } from '@/services/api/sale/get-treatment-history';

// CONSTANTS

export enum EGetTreatmentHistoryAction {
  GET_TREATMENT_HISTORY = 'GET_TREATMENT_HISTORY',
  GET_TREATMENT_HISTORY_REQUEST = 'GET_TREATMENT_HISTORY_REQUEST',
  GET_TREATMENT_HISTORY_SUCCESS = 'GET_TREATMENT_HISTORY_SUCCESS',
  GET_TREATMENT_HISTORY_FAILED = 'GET_TREATMENT_HISTORY_FAILED',
}

// TYPES

export type TGetTreatmentHistoryRequest = {
  type: EGetTreatmentHistoryAction.GET_TREATMENT_HISTORY_REQUEST;
  payload: {
    materials: TGetTreatmentHistoryMaterials;
    successCallback?: (response: TGetTreatmentHistoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTreatmentHistorySuccess = {
  type: EGetTreatmentHistoryAction.GET_TREATMENT_HISTORY_SUCCESS;
  payload: { response: TGetTreatmentHistoryResponse };
};

export type TGetTreatmentHistoryFailed = { type: EGetTreatmentHistoryAction.GET_TREATMENT_HISTORY_FAILED };

// FUNCTION

export const getTreatmentHistoryAction = {
  request: createActionCreator(
    EGetTreatmentHistoryAction.GET_TREATMENT_HISTORY_REQUEST,
    (resolve) =>
      (
        materials: TGetTreatmentHistoryMaterials,
        successCallback?: (response: TGetTreatmentHistoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTreatmentHistoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTreatmentHistoryAction.GET_TREATMENT_HISTORY_SUCCESS,
    (resolve) =>
      (response: TGetTreatmentHistoryResponse): TGetTreatmentHistorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTreatmentHistoryAction.GET_TREATMENT_HISTORY_FAILED,
    (resolve) =>
      (error: unknown): TGetTreatmentHistoryFailed =>
        resolve({ error }),
  ),
};
