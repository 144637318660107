import { TSale } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTreatmentHistoryPaths = {
  id: string | number;
};
export type TGetTreatmentHistoryParams = unknown;

export type TGetTreatmentHistoryMaterials = {
  paths?: TGetTreatmentHistoryPaths;
  params?: TGetTreatmentHistoryParams;
};

export type TGetTreatmentHistoryResponse = TSale;

// FUNCTION

export const getTreatmentHistory = async ({
  paths,
  params,
}: TGetTreatmentHistoryMaterials): Promise<TGetTreatmentHistoryResponse> => {
  const response = await ApiService.get(`/sales/treatments/histories/${paths?.id}`, { params });
  return response.data;
};
