import { createActionCreator } from 'deox';

import { TTotalBookingTodayMaterials, TTotalBookingTodayResponse } from '@/services/api/schedule/total-booking-today';

// CONSTANTS

export enum ETotalBookingTodayAction {
  TOTAL_BOOKING_TODAY = 'TOTAL_BOOKING_TODAY',
  TOTAL_BOOKING_TODAY_REQUEST = 'TOTAL_BOOKING_TODAY_REQUEST',
  TOTAL_BOOKING_TODAY_SUCCESS = 'TOTAL_BOOKING_TODAY_SUCCESS',
  TOTAL_BOOKING_TODAY_FAILED = 'TOTAL_BOOKING_TODAY_FAILED',
}

// TYPES

export type TTotalBookingTodayRequest = {
  type: ETotalBookingTodayAction.TOTAL_BOOKING_TODAY_REQUEST;
  payload: {
    materials: TTotalBookingTodayMaterials;
    successCallback?: (response: TTotalBookingTodayResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TTotalBookingTodaySuccess = {
  type: ETotalBookingTodayAction.TOTAL_BOOKING_TODAY_SUCCESS;
  payload: { response: TTotalBookingTodayResponse };
};

export type TTotalBookingTodayFailed = { type: ETotalBookingTodayAction.TOTAL_BOOKING_TODAY_FAILED };

// FUNCTION

export const totalBookingTodayAction = {
  request: createActionCreator(
    ETotalBookingTodayAction.TOTAL_BOOKING_TODAY_REQUEST,
    (resolve) =>
      (
        materials: TTotalBookingTodayMaterials,
        successCallback?: (response: TTotalBookingTodayResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TTotalBookingTodayRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ETotalBookingTodayAction.TOTAL_BOOKING_TODAY_SUCCESS,
    (resolve) =>
      (response: TTotalBookingTodayResponse): TTotalBookingTodaySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ETotalBookingTodayAction.TOTAL_BOOKING_TODAY_FAILED,
    (resolve) =>
      (error: unknown): TTotalBookingTodayFailed =>
        resolve({ error }),
  ),
};
