import { TScheduleState } from '@/redux/reducers/schedule';
import { TChangeMyScheduleSuccess } from '@/redux/actions/schedule';

export const changeMyScheduleUpdateState = (
  state: TScheduleState,
  action: TChangeMyScheduleSuccess,
): TScheduleState => ({
  ...state,
  changeMyScheduleResponse: action.payload.response,
});
