import { createActionCreator } from 'deox';

import { TGetMyScheduleMaterials, TGetMyScheduleResponse } from '@/services/api/schedule/get-my-schedule';

// CONSTANTS

export enum EGetMyScheduleAction {
  GET_MY_SCHEDULE = 'GET_MY_SCHEDULE',
  GET_MY_SCHEDULE_REQUEST = 'GET_MY_SCHEDULE_REQUEST',
  GET_MY_SCHEDULE_SUCCESS = 'GET_MY_SCHEDULE_SUCCESS',
  GET_MY_SCHEDULE_FAILED = 'GET_MY_SCHEDULE_FAILED',
}

// TYPES

export type TGetMyScheduleRequest = {
  type: EGetMyScheduleAction.GET_MY_SCHEDULE_REQUEST;
  payload: {
    materials: TGetMyScheduleMaterials;
    successCallback?: (response: TGetMyScheduleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetMyScheduleSuccess = {
  type: EGetMyScheduleAction.GET_MY_SCHEDULE_SUCCESS;
  payload: { response?: TGetMyScheduleResponse };
};

export type TGetMyScheduleFailed = { type: EGetMyScheduleAction.GET_MY_SCHEDULE_FAILED };

// FUNCTION

export const getMyScheduleAction = {
  request: createActionCreator(
    EGetMyScheduleAction.GET_MY_SCHEDULE_REQUEST,
    (resolve) =>
      (
        materials: TGetMyScheduleMaterials,
        successCallback?: (response: TGetMyScheduleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetMyScheduleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetMyScheduleAction.GET_MY_SCHEDULE_SUCCESS,
    (resolve) =>
      (response?: TGetMyScheduleResponse): TGetMyScheduleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetMyScheduleAction.GET_MY_SCHEDULE_FAILED,
    (resolve) =>
      (error: unknown): TGetMyScheduleFailed =>
        resolve({ error }),
  ),
};
