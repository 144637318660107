import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getPaymentHistoryAction } from '@/redux/actions';
import { getPaymentHistory, TGetPaymentHistoryResponse } from '@/services/api';

// FUNCTION

export function* getPaymentHistorySaga(action: ActionType<typeof getPaymentHistoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getPaymentHistory, materials);
    const getPaymentHistoryResponse: TGetPaymentHistoryResponse = response as TGetPaymentHistoryResponse;
    yield put(getPaymentHistoryAction.success(getPaymentHistoryResponse));
    successCallback?.(getPaymentHistoryResponse);
  } catch (err) {
    yield put(getPaymentHistoryAction.failure(err));
    failedCallback?.(err);
  }
}
