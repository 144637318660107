import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { TRootState } from '@/redux/reducers';

import { TTextResponsiveProps } from './TextResponsive.types.d';
import './TextResponsive.scss';

const TextResponsive: React.FC<TTextResponsiveProps> = ({ className, responsive, title, color, style, onClick }) => {
  const isMobile = useSelector((state: TRootState) => state.uiReducer.device.isMobile);

  return (
    <div
      className={classNames('TextResponsive', className, {
        [responsive?.desktop || '']: !isMobile,
        [responsive?.mobile || '']: isMobile,
      })}
      style={{ color, ...style }}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

export default TextResponsive;
