export enum EIconName {
  Menu = 'Menu',
  X = 'X',
  ChervonDown = 'ChervonDown',
  OldPhone = 'OldPhone',
  MessageBubble = 'MessageBubble',
  ChervonLeft = 'ChervonLeft',
  ChervonRight = 'ChervonRight',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
}

export enum EIconColor {
  BLACK = '#000',
  WHITE = '#fff',
  JON = '#3C1E1E',

  GRAY_09 = '#1A1A1A',
  GRAY_08 = '#333333',
  GRAY_07 = '#4C4C4C',
  GRAY_05 = '#808080',
  GRAY_04 = '#999999',
  GRAY_03 = '#b3b3b3',
  GRAY_02 = '#CCCCCC',

  RED_07 = '#D61438',
  RED_06 = '#ED3658',

  BLUE_07 = '#6B7AFF',

  SUCCESS = '#1BD693',
}
