import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { paymentCompleteAction } from '@/redux/actions';
import { paymentComplete, TPaymentCompleteResponse } from '@/services/api';

// FUNCTION

export function* paymentCompleteSaga(action: ActionType<typeof paymentCompleteAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(paymentComplete, materials);
    const paymentCompleteResponse: TPaymentCompleteResponse = response as TPaymentCompleteResponse;
    yield put(paymentCompleteAction.success(paymentCompleteResponse));
    successCallback?.(paymentCompleteResponse);
  } catch (err) {
    yield put(paymentCompleteAction.failure(err));
    failedCallback?.(err);
  }
}
