import { TTreatment } from '@/common/models';
import ApiService from '@/services/api';

export enum ETreamentType {
  FIRST_VISIT = 'FIRST_VISIT',
  DAILY_CARE = 'DAILY_CARE',
  SPECIAL_CARE = 'SPECIAL_CARE',
}

// TYPES

export type TGetTreatmentsParams = unknown;

export type TGetTreatmentsMaterials = {
  params?: TGetTreatmentsParams;
};

export type TGetTreatmentsResponse = {
  daily: TTreatment;
  special: TTreatment;
  firstVisit: TTreatment;
};

// FUNCTION

export const getTreatments = async ({ params }: TGetTreatmentsMaterials): Promise<TGetTreatmentsResponse> => {
  const response = await ApiService.get(`/treatment`, { params });
  return response.data;
};
