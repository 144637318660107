import { TSale } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetSaleHistoryPaths = {
  id: string | number;
};
export type TGetSaleHistoryParams = unknown;

export type TGetSaleHistoryMaterials = {
  paths?: TGetSaleHistoryPaths;
  params?: TGetSaleHistoryParams;
};

export type TGetSaleHistoryResponse = TSale;

// FUNCTION

export const getSaleHistory = async ({ paths, params }: TGetSaleHistoryMaterials): Promise<TGetSaleHistoryResponse> => {
  const response = await ApiService.get(`/sales/payments/histories/${paths?.id}`, { params });
  return response.data;
};
