import { TInquiry } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetInquiryPaths = {
  id: string | number;
};
export type TGetInquiryParams = unknown;

export type TGetInquiryMaterials = {
  paths?: TGetInquiryPaths;
  params?: TGetInquiryParams;
};

export type TGetInquiryResponse = TInquiry;

// FUNCTION

export const getInquiry = async ({ paths, params }: TGetInquiryMaterials): Promise<TGetInquiryResponse> => {
  const response = await ApiService.get(`/inquiries/${paths?.id}`, { params });
  return response.data;
};
