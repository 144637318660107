import { createActionCreator } from 'deox';

import { TGetTreatmentMaterials, TGetTreatmentResponse } from '@/services/api/treatment/get-treatment';

// CONSTANTS

export enum EGetTreatmentAction {
  GET_TREATMENT = 'GET_TREATMENT',
  GET_TREATMENT_REQUEST = 'GET_TREATMENT_REQUEST',
  GET_TREATMENT_SUCCESS = 'GET_TREATMENT_SUCCESS',
  GET_TREATMENT_FAILED = 'GET_TREATMENT_FAILED',
}

// TYPES

export type TGetTreatmentRequest = {
  type: EGetTreatmentAction.GET_TREATMENT_REQUEST;
  payload: {
    materials: TGetTreatmentMaterials;
    successCallback?: (response: TGetTreatmentResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTreatmentSuccess = {
  type: EGetTreatmentAction.GET_TREATMENT_SUCCESS;
  payload: { response: TGetTreatmentResponse };
};

export type TGetTreatmentFailed = { type: EGetTreatmentAction.GET_TREATMENT_FAILED };

// FUNCTION

export const getTreatmentAction = {
  request: createActionCreator(
    EGetTreatmentAction.GET_TREATMENT_REQUEST,
    (resolve) =>
      (
        materials: TGetTreatmentMaterials,
        successCallback?: (response: TGetTreatmentResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTreatmentRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTreatmentAction.GET_TREATMENT_SUCCESS,
    (resolve) =>
      (response: TGetTreatmentResponse): TGetTreatmentSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTreatmentAction.GET_TREATMENT_FAILED,
    (resolve) =>
      (error: unknown): TGetTreatmentFailed =>
        resolve({ error }),
  ),
};
