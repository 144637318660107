import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { loginPhoneAction } from '@/redux/actions';
import { loginPhone, TLoginPhoneResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION

export function* loginPhoneSaga(action: ActionType<typeof loginPhoneAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(loginPhone, materials);
    const loginPhoneResponse: TLoginPhoneResponse = response as TLoginPhoneResponse;

    Helpers.storeAccessToken(loginPhoneResponse.jwtAccessToken);

    yield put(loginPhoneAction.success(loginPhoneResponse));
    successCallback?.(loginPhoneResponse);
  } catch (err) {
    yield put(loginPhoneAction.failure(err));
    failedCallback?.(err);
  }
}
