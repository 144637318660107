import { TBookingToday } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetScheduleBookingTodayPaths = {
  id: string | number;
};
export type TGetScheduleBookingTodayParams = unknown;

export type TGetScheduleBookingTodayMaterials = {
  paths?: TGetScheduleBookingTodayPaths;
  params?: TGetScheduleBookingTodayParams;
};

export type TGetScheduleBookingTodayResponse = {
  [key: string]: TBookingToday;
};

// FUNCTION

export const getScheduleBookingToday = async ({
  paths,
  params,
}: TGetScheduleBookingTodayMaterials): Promise<TGetScheduleBookingTodayResponse> => {
  const response = await ApiService.get(`/schedules/booking-schedule-today/${paths?.id}`, { params });
  return response.data;
};
