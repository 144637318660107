import { createActionCreator } from 'deox';

import { TLogoutKakaoMaterials, TLogoutKakaoResponse } from '@/services/api/auth/logout-kakao';

// CONSTANTS

export enum ELogoutKakaoAction {
  LOGOUT_KAKAO = 'LOGOUT_KAKAO',
  LOGOUT_KAKAO_REQUEST = 'LOGOUT_KAKAO_REQUEST',
  LOGOUT_KAKAO_SUCCESS = 'LOGOUT_KAKAO_SUCCESS',
  LOGOUT_KAKAO_FAILED = 'LOGOUT_KAKAO_FAILED',
}

// TYPES

export type TLogoutKakaoRequest = {
  type: ELogoutKakaoAction.LOGOUT_KAKAO_REQUEST;
  payload: {
    materials: TLogoutKakaoMaterials;
    successCallback?: (response: TLogoutKakaoResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TLogoutKakaoSuccess = {
  type: ELogoutKakaoAction.LOGOUT_KAKAO_SUCCESS;
  payload: { response: TLogoutKakaoResponse };
};

export type TLogoutKakaoFailed = { type: ELogoutKakaoAction.LOGOUT_KAKAO_FAILED };

// FUNCTION

export const logoutKakaoAction = {
  request: createActionCreator(
    ELogoutKakaoAction.LOGOUT_KAKAO_REQUEST,
    (resolve) =>
      (
        materials: TLogoutKakaoMaterials,
        successCallback?: (response: TLogoutKakaoResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TLogoutKakaoRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ELogoutKakaoAction.LOGOUT_KAKAO_SUCCESS,
    (resolve) =>
      (response: TLogoutKakaoResponse): TLogoutKakaoSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ELogoutKakaoAction.LOGOUT_KAKAO_FAILED,
    (resolve) =>
      (error: unknown): TLogoutKakaoFailed =>
        resolve({ error }),
  ),
};
