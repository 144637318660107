import { TOrder } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetPaymentHistoriesParams = unknown;

export type TGetPaymentHistoriesMaterials = {
  params?: TGetPaymentHistoriesParams;
};

export type TGetPaymentHistoriesResponse = TOrder[];

// FUNCTION

export const getPaymentHistories = async ({
  params,
}: TGetPaymentHistoriesMaterials): Promise<TGetPaymentHistoriesResponse> => {
  const response = await ApiService.get(`/payments/histories`, { params });
  return response.data;
};
