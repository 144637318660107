import ApiService from '@/services/api';

// TYPES

export type TTotalBookingTodayParams = unknown;

export type TTotalBookingTodayMaterials = {
  params?: TTotalBookingTodayParams;
};

export type TTotalBookingTodayResponse = unknown;

// FUNCTION

export const totalBookingToday = async ({
  params,
}: TTotalBookingTodayMaterials): Promise<TTotalBookingTodayResponse> => {
  const response = await ApiService.get(`/schedules/total-booking-today`, { params });
  return response.data;
};
