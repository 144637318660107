import { TCustomer } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGenerateKakaoCodeParams = unknown;
export type TGenerateKakaoCodeBody = unknown;

export type TGenerateKakaoCodeMaterials = {
  params?: TGenerateKakaoCodeParams;
  body?: TGenerateKakaoCodeBody;
};

export type TGenerateKakaoCodeResponse = {
  jwtAccessToken: string;
  customer: TCustomer;
};

// FUNCTION

export const generateKakaoCode = async ({
  params,
  body,
}: TGenerateKakaoCodeMaterials): Promise<TGenerateKakaoCodeResponse> => {
  const response = await ApiService.post(`/auth/token`, body, { params });
  return response.data;
};
