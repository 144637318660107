import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0C14.1696 0 16.1763 0.419041 18.0201 1.25712C19.8638 2.0952 21.3214 3.23482 22.3929 4.67596C23.4643 6.11711 24 7.68906 24 9.39183C24 11.0946 23.4643 12.6688 22.3929 14.1144C21.3214 15.5599 19.8661 16.7018 18.0268 17.5398C16.1875 18.3779 14.1786 18.797 12 18.797C11.3125 18.797 10.6027 18.7482 9.87054 18.6506C6.69196 20.8412 5 21.9497 4.79464 21.9764C4.69643 22.0118 4.60268 22.0074 4.51339 21.963C4.47768 21.9364 4.45089 21.901 4.43304 21.8566C4.41518 21.8123 4.40625 21.7724 4.40625 21.7369V21.6837C4.45982 21.3378 4.86607 19.8967 5.625 17.3603C3.90179 16.5089 2.53348 15.3803 1.52009 13.9747C0.506696 12.569 0 11.0414 0 9.39183C0 7.68906 0.535714 6.11711 1.60714 4.67596C2.67857 3.23482 4.13616 2.0952 5.97991 1.25712C7.82366 0.419041 9.83036 0 12 0Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
