import ApiService from '@/services/api';

// TYPES

export type TPaymentCompleteParams = unknown;
export type TPaymentCompleteBody = unknown;

export type TPaymentCompleteMaterials = {
  params?: TPaymentCompleteParams;
  body?: TPaymentCompleteBody;
  isNotLogged?: boolean;
};

export type TPaymentCompleteResponse = unknown;

// FUNCTION

export const paymentComplete = async ({
  params,
  body,
  isNotLogged,
}: TPaymentCompleteMaterials): Promise<TPaymentCompleteResponse> => {
  const response = await ApiService.post(`/payments/${isNotLogged ? 'success' : 'complete'}`, body, { params });
  return response.data;
};
