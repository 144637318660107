import { createReducer } from 'deox';

import { TGetTreatmentResponse, TGetTreatmentsResponse } from '@/services/api/treatment';
import { getTreatmentAction, getTreatmentsAction } from '@/redux/actions';
import { getTreatmentUpdateState } from './get-treatment';
import { getTreatmentsUpdateState } from './get-treatments';

export type TTreatmentState = {
  getTreatmentResponse?: TGetTreatmentResponse;
  getTreatmentsResponse?: TGetTreatmentsResponse;
};

const initialState: TTreatmentState = {
  getTreatmentResponse: undefined,
  getTreatmentsResponse: undefined,
};

const TreatmentReducer = createReducer(initialState, (handleAction) => [
  handleAction(getTreatmentAction.success, getTreatmentUpdateState),
  handleAction(getTreatmentsAction.success, getTreatmentsUpdateState),
]);

export default TreatmentReducer;
