import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createScheduleAction } from '@/redux/actions';
import { createSchedule, TCreateScheduleResponse } from '@/services/api';

// FUNCTION

export function* createScheduleSaga(action: ActionType<typeof createScheduleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createSchedule, materials);
    const createScheduleResponse: TCreateScheduleResponse = response as TCreateScheduleResponse;
    yield put(createScheduleAction.success(createScheduleResponse));
    successCallback?.(createScheduleResponse);
  } catch (err) {
    yield put(createScheduleAction.failure(err));
    failedCallback?.(err);
  }
}
