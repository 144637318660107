import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTicketAction } from '@/redux/actions';
import { getTicket, TGetTicketResponse } from '@/services/api';

// FUNCTION

export function* getTicketSaga(action: ActionType<typeof getTicketAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTicket, materials);
    const getTicketResponse: TGetTicketResponse = response as TGetTicketResponse;
    yield put(getTicketAction.success(getTicketResponse));
    successCallback?.(getTicketResponse);
  } catch (err) {
    yield put(getTicketAction.failure(err));
    failedCallback?.(err);
  }
}
