import ApiService from '@/services/api';

// TYPES

export type TCancelMySchedulePaths = {
  id: string | number;
};
export type TCancelMyScheduleBody = unknown;

export type TCancelMyScheduleMaterials = {
  paths?: TCancelMySchedulePaths;
  body?: TCancelMyScheduleBody;
};

export type TCancelMyScheduleResponse = unknown;

// FUNCTION

export const cancelMySchedule = async ({
  paths,
  body,
}: TCancelMyScheduleMaterials): Promise<TCancelMyScheduleResponse> => {
  const response = await ApiService.patch(`/schedules/${paths?.id}/cancel`, body);
  return response.data;
};
