import { createActionCreator } from 'deox';

import {
  TGetTreatmentsHistoriesMaterials,
  TGetTreatmentsHistoriesResponse,
} from '@/services/api/sale/get-treatments-histories';

// CONSTANTS

export enum EGetTreatmentsHistoriesAction {
  GET_TREATMENTS_HISTORIES = 'GET_TREATMENTS_HISTORIES',
  GET_TREATMENTS_HISTORIES_REQUEST = 'GET_TREATMENTS_HISTORIES_REQUEST',
  GET_TREATMENTS_HISTORIES_SUCCESS = 'GET_TREATMENTS_HISTORIES_SUCCESS',
  GET_TREATMENTS_HISTORIES_FAILED = 'GET_TREATMENTS_HISTORIES_FAILED',
}

// TYPES

export type TGetTreatmentsHistoriesRequest = {
  type: EGetTreatmentsHistoriesAction.GET_TREATMENTS_HISTORIES_REQUEST;
  payload: {
    materials: TGetTreatmentsHistoriesMaterials;
    successCallback?: (response: TGetTreatmentsHistoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTreatmentsHistoriesSuccess = {
  type: EGetTreatmentsHistoriesAction.GET_TREATMENTS_HISTORIES_SUCCESS;
  payload: { response: TGetTreatmentsHistoriesResponse };
};

export type TGetTreatmentsHistoriesFailed = { type: EGetTreatmentsHistoriesAction.GET_TREATMENTS_HISTORIES_FAILED };

// FUNCTION

export const getTreatmentsHistoriesAction = {
  request: createActionCreator(
    EGetTreatmentsHistoriesAction.GET_TREATMENTS_HISTORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetTreatmentsHistoriesMaterials,
        successCallback?: (response: TGetTreatmentsHistoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTreatmentsHistoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTreatmentsHistoriesAction.GET_TREATMENTS_HISTORIES_SUCCESS,
    (resolve) =>
      (response: TGetTreatmentsHistoriesResponse): TGetTreatmentsHistoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTreatmentsHistoriesAction.GET_TREATMENTS_HISTORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetTreatmentsHistoriesFailed =>
        resolve({ error }),
  ),
};
