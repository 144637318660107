import { Paths } from '@/pages/routers';
import { THeaderMenuData } from '@/containers/Header/Header.types';
import { TGetTreatmentsResponse } from '@/services/api';
import { TCustomer } from '@/common/models';

export const dataHeaderList = (
  profile?: TCustomer,
  treatments?: TGetTreatmentsResponse,
  id?: string,
  scheduleId?: string,
): THeaderMenuData[] => {
  return [
    {
      key: 1,
      activePath: [Paths.Introduction],
      link: Paths.Introduction,
      label: '소개',
    },
    {
      key: 2,
      isFirstVisit: true,
      activePath: [
        Paths.ProcedureDetail(treatments?.firstVisit.id),
        Paths.ProcedureReservationStep(treatments?.firstVisit.id),
      ],
      link: Paths.ProcedureDetail(treatments?.firstVisit.id),
      label: '첫방문예약',
    },
    {
      key: 3,
      activePath: [
        Paths.Procedures,
        Paths.ProcedureDetail(treatments?.daily.id),
        Paths.ProcedureReservationStep(treatments?.daily.id),
        Paths.ProcedureDetail(treatments?.special.id),
        Paths.ProcedureReservationStep(treatments?.special.id),
      ],
      link: Paths.Procedures,
      label: '관리안내',
      children: [
        {
          key: 99,
          activePath: [
            Paths.ProcedureDetail(treatments?.daily.id),
            Paths.ProcedureReservationStep(treatments?.daily.id),
          ],
          label: treatments?.daily.name || '',
          link: Paths.ProcedureDetail(treatments?.daily.id),
        },
        {
          key: 100,
          activePath: [
            Paths.ProcedureDetail(treatments?.special.id),
            Paths.ProcedureReservationStep(treatments?.special.id),
          ],
          label: treatments?.special.name || '',
          link: Paths.ProcedureDetail(treatments?.special.id),
        },
      ],
    },
    // {
    //   key: 4,
    //   activePath: [Paths.Ticketting, Paths.TickettingDetail(id)],
    //   link: Paths.Ticketting,
    //   label: '티켓팅',
    // },
    {
      key: 5,
      activePath: [Paths.Inquiry, Paths.InquiryDetail(id), Paths.InquiryCreate],
      link: Paths.Inquiry,
      label: '1:1 문의',
    },
    {
      key: 6,
      activePath: [Paths.Notice, Paths.NoticeDetail(id)],
      link: Paths.Notice,
      label: '공지사항',
    },
    {
      key: 7,
      activePath: profile
        ? [
            Paths.MyAccount,
            Paths.PrePaidTickets,
            Paths.RemainPoints,
            Paths.CheckChangeReservation,
            Paths.PaymentHistory,
            Paths.PaymentHistoryDetail(id),
            Paths.TreatmentsList,
            Paths.TreatmentDetail(id),
            Paths.EditProcedureReservationStep(scheduleId, id),
          ]
        : [Paths.Login],
      link: profile ? Paths.MyAccount : Paths.Login,
      label: profile ? '마이페이지' : '로그인',
    },
  ];
};
