import { createActionCreator } from 'deox';

import { TPaymentTicketMaterials, TPaymentTicketResponse } from '@/services/api/ticket/payment-ticket';

// CONSTANTS

export enum EPaymentTicketAction {
  PAYMENT_TICKET = 'PAYMENT_TICKET',
  PAYMENT_TICKET_REQUEST = 'PAYMENT_TICKET_REQUEST',
  PAYMENT_TICKET_SUCCESS = 'PAYMENT_TICKET_SUCCESS',
  PAYMENT_TICKET_FAILED = 'PAYMENT_TICKET_FAILED',
}

// TYPES

export type TPaymentTicketRequest = {
  type: EPaymentTicketAction.PAYMENT_TICKET_REQUEST;
  payload: {
    materials: TPaymentTicketMaterials;
    successCallback?: (response: TPaymentTicketResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TPaymentTicketSuccess = {
  type: EPaymentTicketAction.PAYMENT_TICKET_SUCCESS;
  payload: { response: TPaymentTicketResponse };
};

export type TPaymentTicketFailed = { type: EPaymentTicketAction.PAYMENT_TICKET_FAILED };

// FUNCTION

export const paymentTicketAction = {
  request: createActionCreator(
    EPaymentTicketAction.PAYMENT_TICKET_REQUEST,
    (resolve) =>
      (
        materials: TPaymentTicketMaterials,
        successCallback?: (response: TPaymentTicketResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TPaymentTicketRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EPaymentTicketAction.PAYMENT_TICKET_SUCCESS,
    (resolve) =>
      (response: TPaymentTicketResponse): TPaymentTicketSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EPaymentTicketAction.PAYMENT_TICKET_FAILED,
    (resolve) =>
      (error: unknown): TPaymentTicketFailed =>
        resolve({ error }),
  ),
};
