import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getMyScheduleAction } from '@/redux/actions';
import { getMySchedule, TGetMyScheduleResponse } from '@/services/api';

// FUNCTION

export function* getMyScheduleSaga(action: ActionType<typeof getMyScheduleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getMySchedule, materials);
    const getMyScheduleResponse: TGetMyScheduleResponse = response as TGetMyScheduleResponse;
    yield put(getMyScheduleAction.success(getMyScheduleResponse));
    successCallback?.(getMyScheduleResponse);
  } catch (err) {
    yield put(getMyScheduleAction.failure(err));
    failedCallback?.(err);
  }
}
