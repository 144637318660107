import { createActionCreator } from 'deox';

import {
  TGetTicketsHistoriesMaterials,
  TGetTicketsHistoriesResponse,
} from '@/services/api/ticket/get-tickets-histories';

// CONSTANTS

export enum EGetTicketsHistoriesAction {
  GET_TICKETS_HISTORIES = 'GET_TICKETS_HISTORIES',
  GET_TICKETS_HISTORIES_REQUEST = 'GET_TICKETS_HISTORIES_REQUEST',
  GET_TICKETS_HISTORIES_SUCCESS = 'GET_TICKETS_HISTORIES_SUCCESS',
  GET_TICKETS_HISTORIES_FAILED = 'GET_TICKETS_HISTORIES_FAILED',
}

// TYPES

export type TGetTicketsHistoriesRequest = {
  type: EGetTicketsHistoriesAction.GET_TICKETS_HISTORIES_REQUEST;
  payload: {
    materials: TGetTicketsHistoriesMaterials;
    successCallback?: (response: TGetTicketsHistoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTicketsHistoriesSuccess = {
  type: EGetTicketsHistoriesAction.GET_TICKETS_HISTORIES_SUCCESS;
  payload: { response: TGetTicketsHistoriesResponse };
};

export type TGetTicketsHistoriesFailed = { type: EGetTicketsHistoriesAction.GET_TICKETS_HISTORIES_FAILED };

// FUNCTION

export const getTicketsHistoriesAction = {
  request: createActionCreator(
    EGetTicketsHistoriesAction.GET_TICKETS_HISTORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetTicketsHistoriesMaterials,
        successCallback?: (response: TGetTicketsHistoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTicketsHistoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTicketsHistoriesAction.GET_TICKETS_HISTORIES_SUCCESS,
    (resolve) =>
      (response: TGetTicketsHistoriesResponse): TGetTicketsHistoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTicketsHistoriesAction.GET_TICKETS_HISTORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetTicketsHistoriesFailed =>
        resolve({ error }),
  ),
};
