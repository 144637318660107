import { all, takeLatest } from 'redux-saga/effects';

import {
  cancelMyScheduleAction,
  changeMyScheduleAction,
  createScheduleAction,
  getMyScheduleAction,
  getMySchedulesAction,
  getScheduleBookingTodayAction,
  totalBookingTodayAction,
} from '@/redux/actions';

import { cancelMyScheduleSaga } from './cancel-my-schedule';
import { changeMyScheduleSaga } from './change-my-schedule';
import { createScheduleSaga } from './create-schedule';
import { getMyScheduleSaga } from './get-my-schedule';
import { getMySchedulesSaga } from './get-my-schedules';
import { getScheduleBookingTodaySaga } from './get-schedule-booking-today';
import { totalBookingTodaySaga } from './total-booking-today';

export default function* root(): Generator {
  yield all([
    takeLatest(cancelMyScheduleAction.request.type, cancelMyScheduleSaga),
    takeLatest(changeMyScheduleAction.request.type, changeMyScheduleSaga),
    takeLatest(createScheduleAction.request.type, createScheduleSaga),
    takeLatest(getMyScheduleAction.request.type, getMyScheduleSaga),
    takeLatest(getMySchedulesAction.request.type, getMySchedulesSaga),
    takeLatest(getScheduleBookingTodayAction.request.type, getScheduleBookingTodaySaga),
    takeLatest(totalBookingTodayAction.request.type, totalBookingTodaySaga),
  ]);
}
