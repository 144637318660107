import { createReducer } from 'deox';

import { TGetBranchesResponse } from '@/services/api/branch';
import { getBranchesAction } from '@/redux/actions';
import { getBranchesUpdateState } from './get-branches';

export type TBranchState = {
  getBranchesResponse?: TGetBranchesResponse;
};

const initialState: TBranchState = {
  getBranchesResponse: undefined,
};

const BranchReducer = createReducer(initialState, (handleAction) => [
  handleAction(getBranchesAction.success, getBranchesUpdateState),
]);

export default BranchReducer;
