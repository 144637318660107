import { createActionCreator } from 'deox';

import {
  TGetScheduleBookingTodayMaterials,
  TGetScheduleBookingTodayResponse,
} from '@/services/api/schedule/get-schedule-booking-today';

// CONSTANTS

export enum EGetScheduleBookingTodayAction {
  GET_SCHEDULE_BOOKING_TODAY = 'GET_SCHEDULE_BOOKING_TODAY',
  GET_SCHEDULE_BOOKING_TODAY_REQUEST = 'GET_SCHEDULE_BOOKING_TODAY_REQUEST',
  GET_SCHEDULE_BOOKING_TODAY_SUCCESS = 'GET_SCHEDULE_BOOKING_TODAY_SUCCESS',
  GET_SCHEDULE_BOOKING_TODAY_FAILED = 'GET_SCHEDULE_BOOKING_TODAY_FAILED',
}

// TYPES

export type TGetScheduleBookingTodayRequest = {
  type: EGetScheduleBookingTodayAction.GET_SCHEDULE_BOOKING_TODAY_REQUEST;
  payload: {
    materials: TGetScheduleBookingTodayMaterials;
    successCallback?: (response: TGetScheduleBookingTodayResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetScheduleBookingTodaySuccess = {
  type: EGetScheduleBookingTodayAction.GET_SCHEDULE_BOOKING_TODAY_SUCCESS;
  payload: { response: TGetScheduleBookingTodayResponse };
};

export type TGetScheduleBookingTodayFailed = { type: EGetScheduleBookingTodayAction.GET_SCHEDULE_BOOKING_TODAY_FAILED };

// FUNCTION

export const getScheduleBookingTodayAction = {
  request: createActionCreator(
    EGetScheduleBookingTodayAction.GET_SCHEDULE_BOOKING_TODAY_REQUEST,
    (resolve) =>
      (
        materials: TGetScheduleBookingTodayMaterials,
        successCallback?: (response: TGetScheduleBookingTodayResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetScheduleBookingTodayRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetScheduleBookingTodayAction.GET_SCHEDULE_BOOKING_TODAY_SUCCESS,
    (resolve) =>
      (response: TGetScheduleBookingTodayResponse): TGetScheduleBookingTodaySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetScheduleBookingTodayAction.GET_SCHEDULE_BOOKING_TODAY_FAILED,
    (resolve) =>
      (error: unknown): TGetScheduleBookingTodayFailed =>
        resolve({ error }),
  ),
};
