import { TTicket } from '@/common/models';
import { TPagination } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetTicketsParams = {
  page: number;
  pageSize: number;
};

export type TGetTicketsMaterials = {
  params?: TGetTicketsParams;
};

export type TGetTicketsResponse = {
  data: TTicket[];
  pagination: TPagination;
};

// FUNCTION

export const getTickets = async ({ params }: TGetTicketsMaterials): Promise<TGetTicketsResponse> => {
  const response = await ApiService.get(`/tickets`, { params });
  return response.data;
};
