import { all, takeLatest } from 'redux-saga/effects';

import { getTicketAction, getTicketsHistoriesAction, getTicketsAction, paymentTicketAction } from '@/redux/actions';

import { getTicketSaga } from './get-ticket';
import { getTicketsHistoriesSaga } from './get-tickets-histories';
import { getTicketsSaga } from './get-tickets';
import { paymentTicketSaga } from './payment-ticket';

export default function* root(): Generator {
  yield all([
    takeLatest(getTicketAction.request.type, getTicketSaga),
    takeLatest(getTicketsHistoriesAction.request.type, getTicketsHistoriesSaga),
    takeLatest(getTicketsAction.request.type, getTicketsSaga),
    takeLatest(paymentTicketAction.request.type, paymentTicketSaga),
  ]);
}
