import { createActionCreator } from 'deox';

import { TPaymentCompleteMaterials, TPaymentCompleteResponse } from '@/services/api/payment/payment-complete';

// CONSTANTS

export enum EPaymentCompleteAction {
  PAYMENT_COMPLETE = 'PAYMENT_COMPLETE',
  PAYMENT_COMPLETE_REQUEST = 'PAYMENT_COMPLETE_REQUEST',
  PAYMENT_COMPLETE_SUCCESS = 'PAYMENT_COMPLETE_SUCCESS',
  PAYMENT_COMPLETE_FAILED = 'PAYMENT_COMPLETE_FAILED',
}

// TYPES

export type TPaymentCompleteRequest = {
  type: EPaymentCompleteAction.PAYMENT_COMPLETE_REQUEST;
  payload: {
    materials: TPaymentCompleteMaterials;
    successCallback?: (response: TPaymentCompleteResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TPaymentCompleteSuccess = {
  type: EPaymentCompleteAction.PAYMENT_COMPLETE_SUCCESS;
  payload: { response: TPaymentCompleteResponse };
};

export type TPaymentCompleteFailed = { type: EPaymentCompleteAction.PAYMENT_COMPLETE_FAILED };

// FUNCTION

export const paymentCompleteAction = {
  request: createActionCreator(
    EPaymentCompleteAction.PAYMENT_COMPLETE_REQUEST,
    (resolve) =>
      (
        materials: TPaymentCompleteMaterials,
        successCallback?: (response: TPaymentCompleteResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TPaymentCompleteRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EPaymentCompleteAction.PAYMENT_COMPLETE_SUCCESS,
    (resolve) =>
      (response: TPaymentCompleteResponse): TPaymentCompleteSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EPaymentCompleteAction.PAYMENT_COMPLETE_FAILED,
    (resolve) =>
      (error: unknown): TPaymentCompleteFailed =>
        resolve({ error }),
  ),
};
