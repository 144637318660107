import { TSettingTime, TOpenHour } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetOpenHoursPaths = {
  branchId: string | number;
};
export type TGetOpenHoursParams = unknown;

export type TGetOpenHoursMaterials = {
  paths?: TGetOpenHoursPaths;
  params?: TGetOpenHoursParams;
};

export type TGetOpenHoursResponse = {
  settingTime: TSettingTime[];
  openHour: TOpenHour[];
};

// FUNCTION

export const getOpenHours = async ({ paths, params }: TGetOpenHoursMaterials): Promise<TGetOpenHoursResponse> => {
  const response = await ApiService.get(`/sales/${paths?.branchId}`, { params });
  return response.data;
};
