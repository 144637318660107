import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { generateKakaoCodeAction } from '@/redux/actions';
import { generateKakaoCode, TGenerateKakaoCodeResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION

export function* generateKakaoCodeSaga(action: ActionType<typeof generateKakaoCodeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(generateKakaoCode, materials);
    const generateKakaoCodeResponse: TGenerateKakaoCodeResponse = response as TGenerateKakaoCodeResponse;

    Helpers.storeAccessToken(generateKakaoCodeResponse.jwtAccessToken);

    yield put(generateKakaoCodeAction.success(generateKakaoCodeResponse));
    successCallback?.(generateKakaoCodeResponse);
  } catch (err) {
    yield put(generateKakaoCodeAction.failure(err));
    failedCallback?.(err);
  }
}
