import { TSaleState } from '@/redux/reducers/sale';
import { TGetTreatmentsHistoriesSuccess } from '@/redux/actions/sale';

export const getTreatmentsHistoriesUpdateState = (
  state: TSaleState,
  action: TGetTreatmentsHistoriesSuccess,
): TSaleState => ({
  ...state,
  getTreatmentsHistoriesResponse: action.payload.response,
});
