import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTreatmentAction } from '@/redux/actions';
import { getTreatment, TGetTreatmentResponse } from '@/services/api';

// FUNCTION

export function* getTreatmentSaga(action: ActionType<typeof getTreatmentAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTreatment, materials);
    const getTreatmentResponse: TGetTreatmentResponse = response as TGetTreatmentResponse;
    yield put(getTreatmentAction.success(getTreatmentResponse));
    successCallback?.(getTreatmentResponse);
  } catch (err) {
    yield put(getTreatmentAction.failure(err));
    failedCallback?.(err);
  }
}
