import { TSale } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTreatmentsHistoriesParams = unknown;

export type TGetTreatmentsHistoriesMaterials = {
  params?: TGetTreatmentsHistoriesParams;
};

export type TGetTreatmentsHistoriesResponse = TSale[];

// FUNCTION

export const getTreatmentsHistories = async ({
  params,
}: TGetTreatmentsHistoriesMaterials): Promise<TGetTreatmentsHistoriesResponse> => {
  const response = await ApiService.get(`/sales/treatments/histories`, { params });
  return response.data;
};
