import { TTicket } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTicketsHistoriesParams = unknown;

export type TGetTicketsHistoriesMaterials = {
  params?: TGetTicketsHistoriesParams;
};

export type TGetTicketsHistoriesResponse = TTicket[];

// FUNCTION

export const getTicketsHistories = async ({
  params,
}: TGetTicketsHistoriesMaterials): Promise<TGetTicketsHistoriesResponse> => {
  const response = await ApiService.get(`/tickets/histories`, { params });
  return response.data;
};
