import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TGuestProps } from '@/layouts/Guest/Guest.types';
import Header from '@/containers/Header';
import Footer from '@/containers/Footer';
import Helpers from '@/services/helpers';
import { getBranchesAction, getProfileAction, getTreatmentsAction } from '@/redux/actions';

import './Guest.scss';

const Guest: React.FC<TGuestProps> = ({ children }) => {
  const dispatch = useDispatch();
  const atk = Helpers.getAccessToken();

  const getProfile = useCallback(() => {
    if (atk) dispatch(getProfileAction.request({}));
  }, [dispatch, atk]);

  const getTreatments = useCallback(() => {
    dispatch(getTreatmentsAction.request({}));
  }, [dispatch]);

  const getBranches = useCallback(() => {
    dispatch(getBranchesAction.request({}));
  }, [dispatch]);

  useEffect(() => {
    getBranches();
  }, [getBranches]);

  useEffect(() => {
    getTreatments();
  }, [getTreatments]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  return (
    <div className="Guest">
      <div className="Guest-header">
        <Header />
      </div>
      <div className="Guest-body">{children}</div>
      <div className="Guest-footer">
        <Footer />
      </div>
    </div>
  );
};

export default Guest;
