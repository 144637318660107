import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { logoutKakaoAction } from '@/redux/actions';
import { logoutKakao, TLogoutKakaoResponse } from '@/services/api';

// FUNCTION

export function* logoutKakaoSaga(action: ActionType<typeof logoutKakaoAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(logoutKakao, materials);
    const logoutKakaoResponse: TLogoutKakaoResponse = response as TLogoutKakaoResponse;
    yield put(logoutKakaoAction.success(logoutKakaoResponse));
    successCallback?.(logoutKakaoResponse);
  } catch (err) {
    yield put(logoutKakaoAction.failure(err));
    failedCallback?.(err);
  }
}
