import React, { useEffect } from 'react';
import { Redirect, Router, globalHistory } from '@reach/router';
import { useDispatch } from 'react-redux';
import 'moment/locale/ko';

import { AuthRoute, LayoutPaths, Pages, Paths, PublicRoute } from '@/pages/routers';
import Guest from '@/layouts/Guest';
import { uiActions } from '@/redux/actions';
import { scrollToTop } from '@/utils/functions';

import './App.scss';

const App: React.FC = () => {
  const dispatch = useDispatch();

  globalHistory.listen(() => {
    scrollToTop();
  });

  useEffect(() => {
    const updateSize = (): void => {
      dispatch(uiActions.setDevice(window.innerWidth));
    };
    window.addEventListener('resize', updateSize);
    return (): void => window.removeEventListener('resize', updateSize);
  }, [dispatch]);

  return (
    <div className="App">
      <Router primary={false}>
        <Guest path={LayoutPaths.Guest}>
          <PublicRoute path={Paths.Home} component={Pages.Home} />
          <PublicRoute path={Paths.PrivacyPolicy} component={Pages.PrivacyPolicy} />
          <PublicRoute path={Paths.TermsOfUse} component={Pages.TermsOfUse} />
          <PublicRoute path={Paths.Introduction} component={Pages.Introduction} />
          <PublicRoute path={Paths.Ticketting} component={Pages.Ticketting} />
          <PublicRoute path={Paths.TickettingDetail()} component={Pages.TickettingDetail} />
          <PublicRoute path={Paths.Procedures} component={Pages.Procedures} />
          <PublicRoute path={Paths.ProcedureDetail()} component={Pages.ProcedureDetail} />
          <PublicRoute path={Paths.ProcedureReservationStep()} component={Pages.ProcedureReservationStep} />
          <PublicRoute path={Paths.EditProcedureReservationStep()} component={Pages.ProcedureReservationStep} />
          <PublicRoute path={Paths.Inquiry} component={Pages.Inquiry} />
          <PublicRoute path={Paths.InquiryCreate} component={Pages.InquiryCreate} />
          <PublicRoute path={Paths.InquiryDetail()} component={Pages.InquiryDetail} />
          <PublicRoute path={Paths.Notice} component={Pages.Notice} />
          <PublicRoute path={Paths.NoticeDetail()} component={Pages.NoticeDetail} />

          <PublicRoute path={Paths.MyAccount} component={Pages.MyAccount} />
          <PublicRoute path={Paths.PrePaidTickets} component={Pages.PrePaidTickets} />
          <PublicRoute path={Paths.RemainPoints} component={Pages.RemainPoints} />
          <PublicRoute path={Paths.CheckChangeReservation} component={Pages.CheckChangeReservation} />
          <PublicRoute path={Paths.PaymentHistory} component={Pages.PaymentHistory} />
          <PublicRoute path={Paths.PaymentHistoryDetail()} component={Pages.PaymentHistoryDetail} />
          <PublicRoute path={Paths.TreatmentsList} component={Pages.TreatmentsList} />
          <PublicRoute path={Paths.TreatmentDetail()} component={Pages.TreatmentDetail} />

          <AuthRoute path={Paths.Login} component={Pages.Login} />
          <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Guest}${Paths.Home}`} />
        </Guest>

        <AuthRoute path={Paths.KakaoCallbackLogin} component={Pages.KakaoCallbackLogin} />
        <AuthRoute path={Paths.PhoneCallbackLogin} component={Pages.PhoneCallbackLogin} />
        <PublicRoute path={Paths.KakaoCallbackLogout} component={Pages.KakaoCallbackLogout} />
      </Router>
    </div>
  );
};

export default App;
