import React from 'react';
import { Col, Modal as AntdModal, Row } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { TModalProps } from '@/components/Modal/Modal.types';
import Button from '@/components/Button';
import { EButtonStyleType } from '@/components/Button/Button.enums';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import { TRootState } from '@/redux/reducers';

import './Modal.scss';

const Modal: React.FC<TModalProps> = ({
  visible,
  cancelButton,
  confirmButton,
  centered,
  width,
  wrapClassName,
  className,
  title,
  hideFooter,
  children,
  onClose,
  onSubmit,
}) => {
  const isMobile = useSelector((state: TRootState) => state.uiReducer.device.isMobile);

  return (
    <AntdModal
      footer={null}
      title={null}
      closable={false}
      visible={visible}
      width={width}
      centered={centered}
      onCancel={onClose}
      wrapClassName={classNames('Modal-wrapper', wrapClassName)}
      className={classNames('Modal', className)}
    >
      {title && (
        <div className="Modal-header flex items-center justify-between">
          <div className="Modal-header-title">{title}</div>
          <div className="Modal-header-close" onClick={onClose}>
            <Icon name={EIconName.X} color={EIconColor.GRAY_09} />
          </div>
        </div>
      )}

      <div className="Modal-body">{children}</div>

      {!hideFooter && (
        <div
          className={classNames('Modal-footer', {
            single: (confirmButton && !cancelButton) || (!confirmButton && cancelButton),
          })}
        >
          <Row gutter={20}>
            <Col span={isMobile ? undefined : 12}>
              {confirmButton && (
                <Button
                  onClick={onSubmit}
                  styleType={EButtonStyleType.BLACK}
                  size={isMobile ? 'middle' : 'small'}
                  {...confirmButton}
                />
              )}
            </Col>
            <Col span={isMobile ? undefined : 12}>
              {cancelButton && (
                <Button
                  onClick={onClose}
                  styleType={EButtonStyleType.GRAY}
                  size={isMobile ? 'middle' : 'small'}
                  {...cancelButton}
                />
              )}
            </Col>
          </Row>
        </div>
      )}
    </AntdModal>
  );
};

export default Modal;
