import { all, takeLatest } from 'redux-saga/effects';

import { getTreatmentAction, getTreatmentsAction } from '@/redux/actions';

import { getTreatmentSaga } from './get-treatment';
import { getTreatmentsSaga } from './get-treatments';

export default function* root(): Generator {
  yield all([
    takeLatest(getTreatmentAction.request.type, getTreatmentSaga),
    takeLatest(getTreatmentsAction.request.type, getTreatmentsSaga),
  ]);
}
