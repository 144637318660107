import { TNotice } from '@/common/models';
import { TPagination } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetNoticesParams = {
  page: number;
  pageSize: number;
  search?: string;
};

export type TGetNoticesMaterials = {
  params?: TGetNoticesParams;
};

export type TGetNoticesResponse = {
  pagination: TPagination;
  data: TNotice[];
};

// FUNCTION

export const getNotices = async ({ params }: TGetNoticesMaterials): Promise<TGetNoticesResponse> => {
  const response = await ApiService.get(`/notices`, { params });
  return response.data;
};
