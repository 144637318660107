import { TPaymentState } from '@/redux/reducers/payment';
import { TGetPaymentHistoriesSuccess } from '@/redux/actions/payment';

export const getPaymentHistoriesUpdateState = (
  state: TPaymentState,
  action: TGetPaymentHistoriesSuccess,
): TPaymentState => ({
  ...state,
  getPaymentHistoriesResponse: action.payload.response,
});
