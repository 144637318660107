import { all, takeLatest } from 'redux-saga/effects';

import { createInquiryAction, deleteInquiryAction, getInquiriesAction, getInquiryAction } from '@/redux/actions';

import { createInquirySaga } from './create-inquiry';
import { deleteInquirySaga } from './delete-inquiry';
import { getInquiriesSaga } from './get-inquiries';
import { getInquirySaga } from './get-inquiry';

export default function* root(): Generator {
  yield all([
    takeLatest(createInquiryAction.request.type, createInquirySaga),
    takeLatest(deleteInquiryAction.request.type, deleteInquirySaga),
    takeLatest(getInquiriesAction.request.type, getInquiriesSaga),
    takeLatest(getInquiryAction.request.type, getInquirySaga),
  ]);
}
