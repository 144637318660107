import ApiService from '@/services/api';

// TYPES

export type TCreateInquiryParams = unknown;
export type TCreateInquiryBody = {
  title: string;
  content: string;
};

export type TCreateInquiryMaterials = {
  params?: TCreateInquiryParams;
  body?: TCreateInquiryBody;
};

export type TCreateInquiryResponse = unknown;

// FUNCTION

export const createInquiry = async ({ params, body }: TCreateInquiryMaterials): Promise<TCreateInquiryResponse> => {
  const response = await ApiService.post(`/inquiries`, body, { params });
  return response.data;
};
