import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { paymentTicketAction } from '@/redux/actions';
import { paymentTicket, TPaymentTicketResponse } from '@/services/api';

// FUNCTION

export function* paymentTicketSaga(action: ActionType<typeof paymentTicketAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(paymentTicket, materials);
    const paymentTicketResponse: TPaymentTicketResponse = response as TPaymentTicketResponse;
    yield put(paymentTicketAction.success(paymentTicketResponse));
    successCallback?.(paymentTicketResponse);
  } catch (err) {
    yield put(paymentTicketAction.failure(err));
    failedCallback?.(err);
  }
}
