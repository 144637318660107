import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { totalBookingTodayAction } from '@/redux/actions';
import { totalBookingToday, TTotalBookingTodayResponse } from '@/services/api';

// FUNCTION

export function* totalBookingTodaySaga(action: ActionType<typeof totalBookingTodayAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(totalBookingToday, materials);
    const totalBookingTodayResponse: TTotalBookingTodayResponse = response as TTotalBookingTodayResponse;
    yield put(totalBookingTodayAction.success(totalBookingTodayResponse));
    successCallback?.(totalBookingTodayResponse);
  } catch (err) {
    yield put(totalBookingTodayAction.failure(err));
    failedCallback?.(err);
  }
}
