import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { changeMyScheduleAction } from '@/redux/actions';
import { changeMySchedule, TChangeMyScheduleResponse } from '@/services/api';

// FUNCTION

export function* changeMyScheduleSaga(action: ActionType<typeof changeMyScheduleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(changeMySchedule, materials);
    const changeMyScheduleResponse: TChangeMyScheduleResponse = response as TChangeMyScheduleResponse;
    yield put(changeMyScheduleAction.success(changeMyScheduleResponse));
    successCallback?.(changeMyScheduleResponse);
  } catch (err) {
    yield put(changeMyScheduleAction.failure(err));
    failedCallback?.(err);
  }
}
