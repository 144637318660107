import { createActionCreator } from 'deox';

import { TGetNoticeMaterials, TGetNoticeResponse } from '@/services/api/notice/get-notice';

// CONSTANTS

export enum EGetNoticeAction {
  GET_NOTICE = 'GET_NOTICE',
  GET_NOTICE_REQUEST = 'GET_NOTICE_REQUEST',
  GET_NOTICE_SUCCESS = 'GET_NOTICE_SUCCESS',
  GET_NOTICE_FAILED = 'GET_NOTICE_FAILED',
}

// TYPES

export type TGetNoticeRequest = {
  type: EGetNoticeAction.GET_NOTICE_REQUEST;
  payload: {
    materials: TGetNoticeMaterials;
    successCallback?: (response: TGetNoticeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetNoticeSuccess = {
  type: EGetNoticeAction.GET_NOTICE_SUCCESS;
  payload: { response: TGetNoticeResponse };
};

export type TGetNoticeFailed = { type: EGetNoticeAction.GET_NOTICE_FAILED };

// FUNCTION

export const getNoticeAction = {
  request: createActionCreator(
    EGetNoticeAction.GET_NOTICE_REQUEST,
    (resolve) =>
      (
        materials: TGetNoticeMaterials,
        successCallback?: (response: TGetNoticeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetNoticeRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetNoticeAction.GET_NOTICE_SUCCESS,
    (resolve) =>
      (response: TGetNoticeResponse): TGetNoticeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetNoticeAction.GET_NOTICE_FAILED,
    (resolve) =>
      (error: unknown): TGetNoticeFailed =>
        resolve({ error }),
  ),
};
