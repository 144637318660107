import { createReducer } from 'deox';

import { TGetNoticeResponse, TGetNoticesResponse } from '@/services/api/notice';
import { getNoticeAction, getNoticesAction } from '@/redux/actions';
import { getNoticeUpdateState } from './get-notice';
import { getNoticesUpdateState } from './get-notices';

export type TNoticeState = {
  getNoticeResponse?: TGetNoticeResponse;
  getNoticesResponse?: TGetNoticesResponse;
};

const initialState: TNoticeState = {
  getNoticeResponse: undefined,
  getNoticesResponse: undefined,
};

const NoticeReducer = createReducer(initialState, (handleAction) => [
  handleAction(getNoticeAction.success, getNoticeUpdateState),
  handleAction(getNoticesAction.success, getNoticesUpdateState),
]);

export default NoticeReducer;
