import { createReducer } from 'deox';

import {
  TCreateInquiryResponse,
  TDeleteInquiryResponse,
  TGetInquiriesResponse,
  TGetInquiryResponse,
} from '@/services/api/inquiry';
import { createInquiryAction, deleteInquiryAction, getInquiriesAction, getInquiryAction } from '@/redux/actions';
import { createInquiryUpdateState } from './create-inquiry';
import { deleteInquiryUpdateState } from './delete-inquiry';
import { getInquiriesUpdateState } from './get-inquiries';
import { getInquiryUpdateState } from './get-inquiry';

export type TInquiryState = {
  createInquiryResponse?: TCreateInquiryResponse;
  deleteInquiryResponse?: TDeleteInquiryResponse;
  getInquiriesResponse?: TGetInquiriesResponse;
  getInquiryResponse?: TGetInquiryResponse;
};

const initialState: TInquiryState = {
  createInquiryResponse: undefined,
  deleteInquiryResponse: undefined,
  getInquiriesResponse: undefined,
  getInquiryResponse: undefined,
};

const InquiryReducer = createReducer(initialState, (handleAction) => [
  handleAction(createInquiryAction.success, createInquiryUpdateState),
  handleAction(deleteInquiryAction.success, deleteInquiryUpdateState),
  handleAction(getInquiriesAction.success, getInquiriesUpdateState),
  handleAction(getInquiryAction.success, getInquiryUpdateState),
]);

export default InquiryReducer;
