import { TSchedule } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetMySchedulePaths = {
  id: string | number;
};
export type TGetMyScheduleParams = unknown;

export type TGetMyScheduleMaterials = {
  paths?: TGetMySchedulePaths;
  params?: TGetMyScheduleParams;
};

export type TGetMyScheduleResponse = {
  schedule: TSchedule;
  previousSchedule: TSchedule;
};

// FUNCTION

export const getMySchedule = async ({ paths, params }: TGetMyScheduleMaterials): Promise<TGetMyScheduleResponse> => {
  const response = await ApiService.get(`/schedules/${paths?.id}`, { params });
  return response.data;
};
