import { TScheduleState } from '@/redux/reducers/schedule';
import { TGetScheduleBookingTodaySuccess } from '@/redux/actions/schedule';

export const getScheduleBookingTodayUpdateState = (
  state: TScheduleState,
  action: TGetScheduleBookingTodaySuccess,
): TScheduleState => ({
  ...state,
  getScheduleBookingTodayResponse: action.payload.response,
});
