import { TCustomer } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetProfileParams = unknown;

export type TGetProfileMaterials = {
  params?: TGetProfileParams;
};

export type TGetProfileResponse = TCustomer;

// FUNCTION

export const getProfile = async ({ params }: TGetProfileMaterials): Promise<TGetProfileResponse> => {
  const response = await ApiService.get(`/customer/profile`, { params });
  return response.data;
};
