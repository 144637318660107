import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getMySchedulesAction } from '@/redux/actions';
import { getMySchedules, TGetMySchedulesResponse } from '@/services/api';

// FUNCTION

export function* getMySchedulesSaga(action: ActionType<typeof getMySchedulesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getMySchedules, materials);
    const getMySchedulesResponse: TGetMySchedulesResponse = response as TGetMySchedulesResponse;
    yield put(getMySchedulesAction.success(getMySchedulesResponse));
    successCallback?.(getMySchedulesResponse);
  } catch (err) {
    yield put(getMySchedulesAction.failure(err));
    failedCallback?.(err);
  }
}
