import { createActionCreator } from 'deox';

import {
  TGetPaymentHistoriesMaterials,
  TGetPaymentHistoriesResponse,
} from '@/services/api/payment/get-payment-histories';

// CONSTANTS

export enum EGetPaymentHistoriesAction {
  GET_PAYMENT_HISTORIES = 'GET_PAYMENT_HISTORIES',
  GET_PAYMENT_HISTORIES_REQUEST = 'GET_PAYMENT_HISTORIES_REQUEST',
  GET_PAYMENT_HISTORIES_SUCCESS = 'GET_PAYMENT_HISTORIES_SUCCESS',
  GET_PAYMENT_HISTORIES_FAILED = 'GET_PAYMENT_HISTORIES_FAILED',
}

// TYPES

export type TGetPaymentHistoriesRequest = {
  type: EGetPaymentHistoriesAction.GET_PAYMENT_HISTORIES_REQUEST;
  payload: {
    materials: TGetPaymentHistoriesMaterials;
    successCallback?: (response: TGetPaymentHistoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPaymentHistoriesSuccess = {
  type: EGetPaymentHistoriesAction.GET_PAYMENT_HISTORIES_SUCCESS;
  payload: { response: TGetPaymentHistoriesResponse };
};

export type TGetPaymentHistoriesFailed = { type: EGetPaymentHistoriesAction.GET_PAYMENT_HISTORIES_FAILED };

// FUNCTION

export const getPaymentHistoriesAction = {
  request: createActionCreator(
    EGetPaymentHistoriesAction.GET_PAYMENT_HISTORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetPaymentHistoriesMaterials,
        successCallback?: (response: TGetPaymentHistoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPaymentHistoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPaymentHistoriesAction.GET_PAYMENT_HISTORIES_SUCCESS,
    (resolve) =>
      (response: TGetPaymentHistoriesResponse): TGetPaymentHistoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPaymentHistoriesAction.GET_PAYMENT_HISTORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetPaymentHistoriesFailed =>
        resolve({ error }),
  ),
};
