import { createActionCreator } from 'deox';

import { TGetOpenHoursMaterials, TGetOpenHoursResponse } from '@/services/api/sale/get-open-hours';

// CONSTANTS

export enum EGetOpenHoursAction {
  GET_OPEN_HOURS = 'GET_OPEN_HOURS',
  GET_OPEN_HOURS_REQUEST = 'GET_OPEN_HOURS_REQUEST',
  GET_OPEN_HOURS_SUCCESS = 'GET_OPEN_HOURS_SUCCESS',
  GET_OPEN_HOURS_FAILED = 'GET_OPEN_HOURS_FAILED',
}

// TYPES

export type TGetOpenHoursRequest = {
  type: EGetOpenHoursAction.GET_OPEN_HOURS_REQUEST;
  payload: {
    materials: TGetOpenHoursMaterials;
    successCallback?: (response: TGetOpenHoursResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetOpenHoursSuccess = {
  type: EGetOpenHoursAction.GET_OPEN_HOURS_SUCCESS;
  payload: { response: TGetOpenHoursResponse };
};

export type TGetOpenHoursFailed = { type: EGetOpenHoursAction.GET_OPEN_HOURS_FAILED };

// FUNCTION

export const getOpenHoursAction = {
  request: createActionCreator(
    EGetOpenHoursAction.GET_OPEN_HOURS_REQUEST,
    (resolve) =>
      (
        materials: TGetOpenHoursMaterials,
        successCallback?: (response: TGetOpenHoursResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetOpenHoursRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetOpenHoursAction.GET_OPEN_HOURS_SUCCESS,
    (resolve) =>
      (response: TGetOpenHoursResponse): TGetOpenHoursSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetOpenHoursAction.GET_OPEN_HOURS_FAILED,
    (resolve) =>
      (error: unknown): TGetOpenHoursFailed =>
        resolve({ error }),
  ),
};
