import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getPaymentHistoriesAction } from '@/redux/actions';
import { getPaymentHistories, TGetPaymentHistoriesResponse } from '@/services/api';

// FUNCTION

export function* getPaymentHistoriesSaga(action: ActionType<typeof getPaymentHistoriesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getPaymentHistories, materials);
    const getPaymentHistoriesResponse: TGetPaymentHistoriesResponse = response as TGetPaymentHistoriesResponse;
    yield put(getPaymentHistoriesAction.success(getPaymentHistoriesResponse));
    successCallback?.(getPaymentHistoriesResponse);
  } catch (err) {
    yield put(getPaymentHistoriesAction.failure(err));
    failedCallback?.(err);
  }
}
