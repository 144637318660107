import { TPoint } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetHistoryPointsParams = unknown;

export type TGetHistoryPointsMaterials = {
  params?: TGetHistoryPointsParams;
};

export type TGetHistoryPointsResponse = {
  points: TPoint[];
  availablePoints: number;
};

// FUNCTION

export const getHistoryPoints = async ({ params }: TGetHistoryPointsMaterials): Promise<TGetHistoryPointsResponse> => {
  const response = await ApiService.get(`/points`, { params });
  return response.data;
};
