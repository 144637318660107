import { all, fork } from 'redux-saga/effects';

import authSaga from './auth';
import branchSaga from './branch';
import customerSaga from './customer';
import inquirySaga from './inquiry';
import noticeSaga from './notice';
import paymentSaga from './payment';
import pointSaga from './point';
import saleSaga from './sale';
import scheduleSaga from './schedule';
import ticketSaga from './ticket';
import treatmentSaga from './treatment';

const rootSaga = function* root(): Generator {
  yield all([
    fork(authSaga),
    fork(branchSaga),
    fork(customerSaga),
    fork(inquirySaga),
    fork(noticeSaga),
    fork(paymentSaga),
    fork(pointSaga),
    fork(saleSaga),
    fork(scheduleSaga),
    fork(ticketSaga),
    fork(treatmentSaga),
  ]);
};

export default rootSaga;
