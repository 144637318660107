import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getSaleHistoryAction } from '@/redux/actions';
import { getSaleHistory, TGetSaleHistoryResponse } from '@/services/api';

// FUNCTION

export function* getSaleHistorySaga(action: ActionType<typeof getSaleHistoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getSaleHistory, materials);
    const getSaleHistoryResponse: TGetSaleHistoryResponse = response as TGetSaleHistoryResponse;
    yield put(getSaleHistoryAction.success(getSaleHistoryResponse));
    successCallback?.(getSaleHistoryResponse);
  } catch (err) {
    yield put(getSaleHistoryAction.failure(err));
    failedCallback?.(err);
  }
}
