import { createActionCreator } from 'deox';

import { TCreateScheduleMaterials, TCreateScheduleResponse } from '@/services/api/schedule/create-schedule';

// CONSTANTS

export enum ECreateScheduleAction {
  CREATE_SCHEDULE = 'CREATE_SCHEDULE',
  CREATE_SCHEDULE_REQUEST = 'CREATE_SCHEDULE_REQUEST',
  CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS',
  CREATE_SCHEDULE_FAILED = 'CREATE_SCHEDULE_FAILED',
}

// TYPES

export type TCreateScheduleRequest = {
  type: ECreateScheduleAction.CREATE_SCHEDULE_REQUEST;
  payload: {
    materials: TCreateScheduleMaterials;
    successCallback?: (response: TCreateScheduleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateScheduleSuccess = {
  type: ECreateScheduleAction.CREATE_SCHEDULE_SUCCESS;
  payload: { response: TCreateScheduleResponse };
};

export type TCreateScheduleFailed = { type: ECreateScheduleAction.CREATE_SCHEDULE_FAILED };

// FUNCTION

export const createScheduleAction = {
  request: createActionCreator(
    ECreateScheduleAction.CREATE_SCHEDULE_REQUEST,
    (resolve) =>
      (
        materials: TCreateScheduleMaterials,
        successCallback?: (response: TCreateScheduleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateScheduleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateScheduleAction.CREATE_SCHEDULE_SUCCESS,
    (resolve) =>
      (response: TCreateScheduleResponse): TCreateScheduleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateScheduleAction.CREATE_SCHEDULE_FAILED,
    (resolve) =>
      (error: unknown): TCreateScheduleFailed =>
        resolve({ error }),
  ),
};
