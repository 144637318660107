import { TSale } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetSaleHistoriesParams = unknown;

export type TGetSaleHistoriesMaterials = {
  params?: TGetSaleHistoriesParams;
};

export type TGetSaleHistoriesResponse = TSale[];

// FUNCTION

export const getSaleHistories = async ({ params }: TGetSaleHistoriesMaterials): Promise<TGetSaleHistoriesResponse> => {
  const response = await ApiService.get(`/sales/payments/histories`, { params });
  return response.data;
};
