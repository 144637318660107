import { all, takeLatest } from 'redux-saga/effects';

import {
  authLoginAction,
  generateKakaoCodeAction,
  loginKakaoAction,
  loginPhoneAction,
  logoutKakaoAction,
} from '@/redux/actions';

import { authLoginSaga } from './auth-login';
import { generateKakaoCodeSaga } from './generate-kakao-code';
import { loginKakaoSaga } from './login-kakao';
import { loginPhoneSaga } from './login-phone';
import { logoutKakaoSaga } from './logout-kakao';

export default function* root(): Generator {
  yield all([
    takeLatest(authLoginAction.request.type, authLoginSaga),
    takeLatest(generateKakaoCodeAction.request.type, generateKakaoCodeSaga),
    takeLatest(loginKakaoAction.request.type, loginKakaoSaga),
    takeLatest(loginPhoneAction.request.type, loginPhoneSaga),
    takeLatest(logoutKakaoAction.request.type, logoutKakaoSaga),
  ]);
}
