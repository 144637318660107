import { all, takeLatest } from 'redux-saga/effects';

import { getPaymentHistoriesAction, getPaymentHistoryAction, paymentCompleteAction } from '@/redux/actions';

import { getPaymentHistoriesSaga } from './get-payment-histories';
import { getPaymentHistorySaga } from './get-payment-history';
import { paymentCompleteSaga } from './payment-complete';

export default function* root(): Generator {
  yield all([
    takeLatest(getPaymentHistoriesAction.request.type, getPaymentHistoriesSaga),
    takeLatest(getPaymentHistoryAction.request.type, getPaymentHistorySaga),
    takeLatest(paymentCompleteAction.request.type, paymentCompleteSaga),
  ]);
}
