import { createActionCreator } from 'deox';

import { TChangeMyScheduleMaterials, TChangeMyScheduleResponse } from '@/services/api/schedule/change-my-schedule';

// CONSTANTS

export enum EChangeMyScheduleAction {
  CHANGE_MY_SCHEDULE = 'CHANGE_MY_SCHEDULE',
  CHANGE_MY_SCHEDULE_REQUEST = 'CHANGE_MY_SCHEDULE_REQUEST',
  CHANGE_MY_SCHEDULE_SUCCESS = 'CHANGE_MY_SCHEDULE_SUCCESS',
  CHANGE_MY_SCHEDULE_FAILED = 'CHANGE_MY_SCHEDULE_FAILED',
}

// TYPES

export type TChangeMyScheduleRequest = {
  type: EChangeMyScheduleAction.CHANGE_MY_SCHEDULE_REQUEST;
  payload: {
    materials: TChangeMyScheduleMaterials;
    successCallback?: (response: TChangeMyScheduleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TChangeMyScheduleSuccess = {
  type: EChangeMyScheduleAction.CHANGE_MY_SCHEDULE_SUCCESS;
  payload: { response: TChangeMyScheduleResponse };
};

export type TChangeMyScheduleFailed = { type: EChangeMyScheduleAction.CHANGE_MY_SCHEDULE_FAILED };

// FUNCTION

export const changeMyScheduleAction = {
  request: createActionCreator(
    EChangeMyScheduleAction.CHANGE_MY_SCHEDULE_REQUEST,
    (resolve) =>
      (
        materials: TChangeMyScheduleMaterials,
        successCallback?: (response: TChangeMyScheduleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TChangeMyScheduleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EChangeMyScheduleAction.CHANGE_MY_SCHEDULE_SUCCESS,
    (resolve) =>
      (response: TChangeMyScheduleResponse): TChangeMyScheduleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EChangeMyScheduleAction.CHANGE_MY_SCHEDULE_FAILED,
    (resolve) =>
      (error: unknown): TChangeMyScheduleFailed =>
        resolve({ error }),
  ),
};
