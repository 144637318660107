import { TOrder } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetPaymentHistoryPaths = {
  id: string | number;
};
export type TGetPaymentHistoryParams = unknown;

export type TGetPaymentHistoryMaterials = {
  paths?: TGetPaymentHistoryPaths;
  params?: TGetPaymentHistoryParams;
};

export type TGetPaymentHistoryResponse = TOrder;

// FUNCTION

export const getPaymentHistory = async ({
  paths,
  params,
}: TGetPaymentHistoryMaterials): Promise<TGetPaymentHistoryResponse> => {
  const response = await ApiService.get(`/payment/${paths?.id}`, { params });
  return response.data;
};
