import { TSchedule } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetMySchedulesParams = unknown;

export type TGetMySchedulesMaterials = {
  params?: TGetMySchedulesParams;
};

export type TGetMySchedulesResponse = TSchedule[];

// FUNCTION

export const getMySchedules = async ({ params }: TGetMySchedulesMaterials): Promise<TGetMySchedulesResponse> => {
  const response = await ApiService.get(`/schedules`, { params });
  return response.data;
};
