import { createReducer } from 'deox';

import { TGetHistoryPointResponse, TGetHistoryPointsResponse } from '@/services/api/point';
import { getHistoryPointAction, getHistoryPointsAction } from '@/redux/actions';
import { getHistoryPointUpdateState } from './get-history-point';
import { getHistoryPointsUpdateState } from './get-history-points';

export type TPointState = {
  getHistoryPointResponse?: TGetHistoryPointResponse;
  getHistoryPointsResponse?: TGetHistoryPointsResponse;
};

const initialState: TPointState = {
  getHistoryPointResponse: undefined,
  getHistoryPointsResponse: undefined,
};

const PointReducer = createReducer(initialState, (handleAction) => [
  handleAction(getHistoryPointAction.success, getHistoryPointUpdateState),
  handleAction(getHistoryPointsAction.success, getHistoryPointsUpdateState),
]);

export default PointReducer;
