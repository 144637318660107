import { createReducer } from 'deox';
import { uiActions } from '@/redux/actions';

export const BREAKPOINT = 991;

export enum EDeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

const initialState = {
  device: {
    type: window.innerWidth > BREAKPOINT ? EDeviceType.DESKTOP : EDeviceType.MOBILE,
    width: window.innerWidth,
    isMobile: window.innerWidth <= BREAKPOINT,
  },
};

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(uiActions.setDevice, (state, { payload }) => ({
    ...state,
    device: {
      type: payload.deviceWidth > BREAKPOINT ? EDeviceType.DESKTOP : EDeviceType.MOBILE,
      width: payload.deviceWidth,
      isMobile: payload.deviceWidth <= BREAKPOINT,
    },
  })),
]);

export default reducer;
