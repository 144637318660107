import { createActionCreator } from 'deox';

import { TGenerateKakaoCodeMaterials, TGenerateKakaoCodeResponse } from '@/services/api/auth/generate-kakao-code';

// CONSTANTS

export enum EGenerateKakaoCodeAction {
  GENERATE_KAKAO_CODE = 'GENERATE_KAKAO_CODE',
  GENERATE_KAKAO_CODE_REQUEST = 'GENERATE_KAKAO_CODE_REQUEST',
  GENERATE_KAKAO_CODE_SUCCESS = 'GENERATE_KAKAO_CODE_SUCCESS',
  GENERATE_KAKAO_CODE_FAILED = 'GENERATE_KAKAO_CODE_FAILED',
}

// TYPES

export type TGenerateKakaoCodeRequest = {
  type: EGenerateKakaoCodeAction.GENERATE_KAKAO_CODE_REQUEST;
  payload: {
    materials: TGenerateKakaoCodeMaterials;
    successCallback?: (response: TGenerateKakaoCodeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGenerateKakaoCodeSuccess = {
  type: EGenerateKakaoCodeAction.GENERATE_KAKAO_CODE_SUCCESS;
  payload: { response: TGenerateKakaoCodeResponse };
};

export type TGenerateKakaoCodeFailed = { type: EGenerateKakaoCodeAction.GENERATE_KAKAO_CODE_FAILED };

// FUNCTION

export const generateKakaoCodeAction = {
  request: createActionCreator(
    EGenerateKakaoCodeAction.GENERATE_KAKAO_CODE_REQUEST,
    (resolve) =>
      (
        materials: TGenerateKakaoCodeMaterials,
        successCallback?: (response: TGenerateKakaoCodeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGenerateKakaoCodeRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGenerateKakaoCodeAction.GENERATE_KAKAO_CODE_SUCCESS,
    (resolve) =>
      (response: TGenerateKakaoCodeResponse): TGenerateKakaoCodeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGenerateKakaoCodeAction.GENERATE_KAKAO_CODE_FAILED,
    (resolve) =>
      (error: unknown): TGenerateKakaoCodeFailed =>
        resolve({ error }),
  ),
};
