import { createActionCreator } from 'deox';

import { TCreateInquiryMaterials, TCreateInquiryResponse } from '@/services/api/inquiry/create-inquiry';

// CONSTANTS

export enum ECreateInquiryAction {
  CREATE_INQUIRY = 'CREATE_INQUIRY',
  CREATE_INQUIRY_REQUEST = 'CREATE_INQUIRY_REQUEST',
  CREATE_INQUIRY_SUCCESS = 'CREATE_INQUIRY_SUCCESS',
  CREATE_INQUIRY_FAILED = 'CREATE_INQUIRY_FAILED',
}

// TYPES

export type TCreateInquiryRequest = {
  type: ECreateInquiryAction.CREATE_INQUIRY_REQUEST;
  payload: {
    materials: TCreateInquiryMaterials;
    successCallback?: (response: TCreateInquiryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateInquirySuccess = {
  type: ECreateInquiryAction.CREATE_INQUIRY_SUCCESS;
  payload: { response: TCreateInquiryResponse };
};

export type TCreateInquiryFailed = { type: ECreateInquiryAction.CREATE_INQUIRY_FAILED };

// FUNCTION

export const createInquiryAction = {
  request: createActionCreator(
    ECreateInquiryAction.CREATE_INQUIRY_REQUEST,
    (resolve) =>
      (
        materials: TCreateInquiryMaterials,
        successCallback?: (response: TCreateInquiryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateInquiryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateInquiryAction.CREATE_INQUIRY_SUCCESS,
    (resolve) =>
      (response: TCreateInquiryResponse): TCreateInquirySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateInquiryAction.CREATE_INQUIRY_FAILED,
    (resolve) =>
      (error: unknown): TCreateInquiryFailed =>
        resolve({ error }),
  ),
};
