import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Link, navigate, useLocation } from '@reach/router';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import LogoKr from '@/assets/images/logo-kr.svg';
import LogoEn from '@/assets/images/logo-en.svg';
import { TRootState } from '@/redux/reducers';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import { Paths } from '@/pages/routers';

import { dataHeaderList } from './Header.data';
import { THeaderMenuData, THeaderProps } from './Header.types.d';
import './Header.scss';

const Header: React.FC<THeaderProps> = () => {
  const { pathname } = useLocation();
  const [activeKeyMenu, setActiveKeyMenu] = useState<string | number>();
  const [visibleMenuMobile, setVisibleMenuMobile] = useState<boolean>(false);

  const pathsArray = useLocation().pathname.split('/');
  const id = pathsArray[pathsArray.length - 1];
  const scheduleId = pathsArray[pathsArray.length - 2];

  const isMobile = useSelector((state: TRootState) => state.uiReducer.device.isMobile);
  const treatmentsState = useSelector((state: TRootState) => state.treatmentReducer.getTreatmentsResponse);
  const profileState = useSelector((state: TRootState) => state.customerReducer.getProfileResponse);

  const handleClickMenu = (data: THeaderMenuData): void => {
    const isExistedChildren = data?.children && data.children.length > 0;

    if (data.isFirstVisit) {
      const idFirstVisitTreatment = treatmentsState?.firstVisit?.id;
      if (idFirstVisitTreatment) {
        navigate(Paths.ProcedureDetail(idFirstVisitTreatment));
      }
    } else if (data.link) {
      navigate(data.link);
    } else if (isMobile && isExistedChildren) {
      const isClickSelf = activeKeyMenu === data.key;
      setActiveKeyMenu(isClickSelf ? undefined : data.key);
    }
  };

  const handleHoverMenu = (data: THeaderMenuData): void => {
    const isExistedChildren = data?.children && data.children.length > 0;

    if (!isMobile && isExistedChildren) {
      const isClickSelf = activeKeyMenu === data.key;
      setActiveKeyMenu(isClickSelf ? undefined : data.key);
    }
  };

  const handleOpenMenuMobile = (): void => {
    setVisibleMenuMobile(true);
  };

  const handleCloseMenuMobile = (): void => {
    setActiveKeyMenu(undefined);
    setVisibleMenuMobile(false);
  };

  useEffect(() => {
    if (!isMobile && activeKeyMenu) {
      const activeChildren = document.querySelector('.Header-list-item-children.active');
      const submenuOverlay = document.querySelector('.Header-submenu-overlay');
      if (activeChildren && submenuOverlay) {
        submenuOverlay.setAttribute('style', `height: ${activeChildren.clientHeight}px;`);
      }
    }
  }, [isMobile, activeKeyMenu]);

  useEffect(() => {
    setActiveKeyMenu(undefined);
    setVisibleMenuMobile(false);
  }, [isMobile, pathname]);

  useEffect(() => {
    if (visibleMenuMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [visibleMenuMobile]);

  return (
    <div className="Header">
      <div
        className={classNames('Header-submenu-overlay', { active: !!activeKeyMenu || visibleMenuMobile })}
        onClick={handleCloseMenuMobile}
        onMouseEnter={(): void => setActiveKeyMenu(activeKeyMenu)}
        onMouseLeave={(): void => setActiveKeyMenu(undefined)}
      />
      <div className="container">
        <div className="Header-wrapper">
          <Row justify="space-between" align="middle">
            <Col>
              <Link to={Paths.Home} className="Header-logo">
                <img src={isMobile ? LogoEn : LogoKr} alt="" />
              </Link>
            </Col>
            <Col>
              {isMobile && (
                <div className="Header-btn-mobile" onClick={handleOpenMenuMobile}>
                  <Icon name={EIconName.Menu} color={EIconColor.GRAY_09} />
                </div>
              )}

              <div className={classNames('Header-list', { active: visibleMenuMobile })}>
                {isMobile && (
                  <div className="Header-list-close" onClick={handleCloseMenuMobile}>
                    <Icon name={EIconName.X} color={EIconColor.GRAY_08} />
                  </div>
                )}

                <Row gutter={50}>
                  {dataHeaderList(profileState, treatmentsState, id, scheduleId).map((item) => {
                    const isExistedChildren = item?.children && item.children.length > 0;
                    return (
                      <Col span={isMobile ? 24 : undefined} key={item.key}>
                        <div
                          className={classNames('Header-list-item', {
                            active: item.activePath.includes(pathname),
                          })}
                        >
                          <div
                            className="Header-list-item-parent flex justify-between items-center"
                            onClick={(): void => handleClickMenu(item)}
                            onMouseEnter={(): void => handleHoverMenu(item)}
                            onMouseLeave={(): void => handleHoverMenu(item)}
                          >
                            <div className="Header-list-item-label headline-1">{item.label}</div>
                            {isMobile && isExistedChildren && (
                              <div className="Header-list-item-arrow">
                                <Icon name={EIconName.ChervonDown} color={EIconColor.GRAY_02} />
                              </div>
                            )}
                          </div>

                          {isExistedChildren && (
                            <div
                              className={classNames('Header-list-item-children', {
                                active: item.key === activeKeyMenu,
                              })}
                              onMouseEnter={(): void => setActiveKeyMenu(activeKeyMenu)}
                              onMouseLeave={(): void => setActiveKeyMenu(undefined)}
                            >
                              {item.children?.map((child) => (
                                <div
                                  key={child.key}
                                  className={classNames('Header-list-item body-03 uppercase', {
                                    active: child.activePath.includes(pathname),
                                  })}
                                  onClick={(): void => handleClickMenu(child)}
                                >
                                  {child.label}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Header;
