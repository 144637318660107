import { createActionCreator } from 'deox';

import { TLoginPhoneMaterials, TLoginPhoneResponse } from '@/services/api/auth/login-phone';

// CONSTANTS

export enum ELoginPhoneAction {
  LOGIN_PHONE = 'LOGIN_PHONE',
  LOGIN_PHONE_REQUEST = 'LOGIN_PHONE_REQUEST',
  LOGIN_PHONE_SUCCESS = 'LOGIN_PHONE_SUCCESS',
  LOGIN_PHONE_FAILED = 'LOGIN_PHONE_FAILED',
}

// TYPES

export type TLoginPhoneRequest = {
  type: ELoginPhoneAction.LOGIN_PHONE_REQUEST;
  payload: {
    materials: TLoginPhoneMaterials;
    successCallback?: (response: TLoginPhoneResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TLoginPhoneSuccess = {
  type: ELoginPhoneAction.LOGIN_PHONE_SUCCESS;
  payload: { response: TLoginPhoneResponse };
};

export type TLoginPhoneFailed = { type: ELoginPhoneAction.LOGIN_PHONE_FAILED };

// FUNCTION

export const loginPhoneAction = {
  request: createActionCreator(
    ELoginPhoneAction.LOGIN_PHONE_REQUEST,
    (resolve) =>
      (
        materials: TLoginPhoneMaterials,
        successCallback?: (response: TLoginPhoneResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TLoginPhoneRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ELoginPhoneAction.LOGIN_PHONE_SUCCESS,
    (resolve) =>
      (response: TLoginPhoneResponse): TLoginPhoneSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ELoginPhoneAction.LOGIN_PHONE_FAILED,
    (resolve) =>
      (error: unknown): TLoginPhoneFailed =>
        resolve({ error }),
  ),
};
