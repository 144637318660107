import React, { lazy, Suspense } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';

import Helpers from '@/services/helpers';
import Loading from '@/components/Loading';

const retryLoadComponent = (fn: () => Promise<unknown>, retriesLeft = 5, interval = 1000): any =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retryLoadComponent(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

const Home = lazy(() => retryLoadComponent(() => import('@/pages/Home')));
const Introduction = lazy(() => retryLoadComponent(() => import('@/pages/Introduction')));
const PrivacyPolicy = lazy(() => retryLoadComponent(() => import('@/pages/PrivacyPolicy')));
const TermsOfUse = lazy(() => retryLoadComponent(() => import('@/pages/TermsOfUse')));
const Ticketting = lazy(() => retryLoadComponent(() => import('@/pages/Ticketting')));
const TickettingDetail = lazy(() => retryLoadComponent(() => import('@/pages/TickettingDetail')));
const Procedures = lazy(() => retryLoadComponent(() => import('@/pages/Procedures')));
const ProcedureDetail = lazy(() => retryLoadComponent(() => import('@/pages/ProcedureDetail')));
const ProcedureReservationStep = lazy(() => retryLoadComponent(() => import('@/pages/ProcedureReservationStep')));
const MyAccount = lazy(() => retryLoadComponent(() => import('@/pages/MyAccount')));
const PrePaidTickets = lazy(() => retryLoadComponent(() => import('@/pages/PrePaidTickets')));
const RemainPoints = lazy(() => retryLoadComponent(() => import('@/pages/RemainPoints')));
const CheckChangeReservation = lazy(() => retryLoadComponent(() => import('@/pages/CheckChangeReservation')));
const PaymentHistory = lazy(() => retryLoadComponent(() => import('@/pages/PaymentHistory')));
const PaymentHistoryDetail = lazy(() => retryLoadComponent(() => import('@/pages/PaymentHistoryDetail')));
const TreatmentsList = lazy(() => retryLoadComponent(() => import('@/pages/TreatmentsList')));
const TreatmentDetail = lazy(() => retryLoadComponent(() => import('@/pages/TreatmentDetail')));
const Inquiry = lazy(() => retryLoadComponent(() => import('@/pages/Inquiry')));
const Notice = lazy(() => retryLoadComponent(() => import('@/pages/Notice')));
const InquiryCreate = lazy(() => retryLoadComponent(() => import('@/pages/InquiryCreate')));
const InquiryDetail = lazy(() => retryLoadComponent(() => import('@/pages/InquiryDetail')));
const NoticeDetail = lazy(() => retryLoadComponent(() => import('@/pages/NoticeDetail')));

const Login = lazy(() => retryLoadComponent(() => import('@/pages/Login')));
const KakaoCallbackLogin = lazy(() => retryLoadComponent(() => import('@/pages/KakaoCallbackLogin')));
const KakaoCallbackLogout = lazy(() => retryLoadComponent(() => import('@/pages/KakaoCallbackLogout')));
const PhoneCallbackLogin = lazy(() => retryLoadComponent(() => import('@/pages/PhoneCallbackLogin')));

const Dashboard = lazy(() => retryLoadComponent(() => import('@/pages/Dashboard')));

export const LayoutPaths = {
  Guest: '/',
  Auth: '/auth',
  Admin: '/admin',
};

export const ModulePaths = {
  Procedures: '/procedures',
  MyAccount: '/my-account',
  Ticketting: '/ticketting',
  Inquiry: '/inquiry',
  Notice: '/notice',
};

export const Paths = {
  Home: '/',
  Introduction: '/introduction',
  PrivacyPolicy: '/privacy-policy',
  TermsOfUse: '/terms-of-use',
  Procedures: ModulePaths.Procedures,
  ProcedureDetail: (id?: string): string => `${ModulePaths.Procedures}/detail/${id || ':id'}`,
  ProcedureReservationStep: (id?: string): string => `${ModulePaths.Procedures}/reservation/${id || ':id'}`,
  EditProcedureReservationStep: (scheduleId?: string, id?: string): string =>
    `${ModulePaths.MyAccount}/reservations/edit/${scheduleId || ':scheduleId'}/${id || ':id'}`,
  MyAccount: ModulePaths.MyAccount,
  PrePaidTickets: `${ModulePaths.MyAccount}/pre-paid-tickets`,
  RemainPoints: `${ModulePaths.MyAccount}/remain-points`,
  CheckChangeReservation: `${ModulePaths.MyAccount}/reservations`,
  PaymentHistory: `${ModulePaths.MyAccount}/payment-history`,
  PaymentHistoryDetail: (id?: string): string => `${ModulePaths.MyAccount}/payment-history/${id || ':id'}`,
  TreatmentsList: `${ModulePaths.MyAccount}/treatments`,
  TreatmentDetail: (id?: string): string => `${ModulePaths.MyAccount}/treatments/${id || ':id'}`,
  Inquiry: ModulePaths.Inquiry,
  InquiryCreate: `${ModulePaths.Inquiry}/create`,
  InquiryDetail: (id?: string): string => `${ModulePaths.Inquiry}/${id || ':id'}`,
  Ticketting: ModulePaths.Ticketting,
  TickettingDetail: (id?: string): string => `${ModulePaths.Ticketting}/${id || ':id'}`,
  Notice: ModulePaths.Notice,
  NoticeDetail: (id?: string): string => `${ModulePaths.Notice}/${id || ':id'}`,

  Login: '/login',
  KakaoCallbackLogin: '/login/kakao-callback',
  KakaoCallbackLogout: '/logout/kakao-callback',
  PhoneCallbackLogin: '/login/phone-callback',

  Dashboard: '/',
  Rest: '*',
};

export const Pages = {
  Home,
  Introduction,
  PrivacyPolicy,
  TermsOfUse,
  Ticketting,
  TickettingDetail,
  Procedures,
  ProcedureDetail,
  ProcedureReservationStep,
  MyAccount,
  PrePaidTickets,
  RemainPoints,
  CheckChangeReservation,
  PaymentHistory,
  PaymentHistoryDetail,
  TreatmentsList,
  TreatmentDetail,
  Inquiry,
  Notice,
  InquiryDetail,
  InquiryCreate,
  NoticeDetail,

  Login,
  KakaoCallbackLogin,
  KakaoCallbackLogout,
  PhoneCallbackLogin,

  Dashboard,
};

interface IRouteProps extends RouteComponentProps {
  component: React.FC;
}

export const AuthRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();

  return loggedIn ? (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Admin} />
  ) : (
    <Suspense
      fallback={
        <div style={{ padding: '100px 0' }}>
          <Loading />
        </div>
      }
    >
      <Component {...rest} />
    </Suspense>
  );
};

export const ProtectedRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();

  return loggedIn ? (
    <Suspense
      fallback={
        <div style={{ padding: '100px 0' }}>
          <Loading />
        </div>
      }
    >
      <Component {...rest} />
    </Suspense>
  ) : (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Auth} />
  );
};

export const PublicRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => (
  <Suspense
    fallback={
      <div style={{ padding: '100px 0' }}>
        <Loading />
      </div>
    }
  >
    <Component {...rest} />
  </Suspense>
);
