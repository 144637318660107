import ApiService from '@/services/api';

// TYPES

export type TLogoutKakaoParams = unknown;

export type TLogoutKakaoMaterials = {
  params?: TLogoutKakaoParams;
};

export type TLogoutKakaoResponse = string;

// FUNCTION

export const logoutKakao = async ({ params }: TLogoutKakaoMaterials): Promise<TLogoutKakaoResponse> => {
  const response = await ApiService.get(`/auth/logout/kakao`, { params });
  return response.data;
};
