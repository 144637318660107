import { all, takeLatest } from 'redux-saga/effects';

import {
  getOpenHoursAction,
  getSaleHistoriesAction,
  getSaleHistoryAction,
  getTreatmentHistoryAction,
  getTreatmentsHistoriesAction,
} from '@/redux/actions';

import { getOpenHoursSaga } from './get-open-hours';
import { getSaleHistoriesSaga } from './get-sale-histories';
import { getSaleHistorySaga } from './get-sale-history';
import { getTreatmentHistorySaga } from './get-treatment-history';
import { getTreatmentsHistoriesSaga } from './get-treatments-histories';

export default function* root(): Generator {
  yield all([
    takeLatest(getOpenHoursAction.request.type, getOpenHoursSaga),
    takeLatest(getSaleHistoriesAction.request.type, getSaleHistoriesSaga),
    takeLatest(getSaleHistoryAction.request.type, getSaleHistorySaga),
    takeLatest(getTreatmentHistoryAction.request.type, getTreatmentHistorySaga),
    takeLatest(getTreatmentsHistoriesAction.request.type, getTreatmentsHistoriesSaga),
  ]);
}
