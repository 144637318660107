import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="7" width="24" height="2" fill={color} />
      <rect x="4" y="15" width="24" height="2" fill={color} />
      <rect x="4" y="23" width="24" height="2" fill={color} />
    </svg>
  );
};

export default Svg;
